import { Col, Row } from 'antd'
import React from 'react'
import PricesHistoryTable from 'features/prices/PricesHistoryTable'

export default function PricesHistory() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<PricesHistoryTable />
			</Col>
		</Row>
	)
}
