import React, { FC } from 'react'
import { Select, Checkbox, Space } from 'antd'
import useActionsData from '../hooks/actions/useActionsData'

const { Option } = Select

interface IActionTypePicker {
	selectedActionType: string[] | null
	setSelectedActionType: (type: string[] | null) => void
}

const actionTypes: Record<string, string> = {
	regular: 'Акция',
	auto: 'Автоакция',
	special: 'Специальное предложение',
	sale: 'Дни распродаж',
}

export const PickActionType: FC<IActionTypePicker> = ({
	selectedActionType,
	setSelectedActionType,
}) => {
	const { promotionTypes } = useActionsData()

	// const handleCheckboxChange = (checked: boolean, id: string) => {
	// 	const newSelectedTypes = selectedActionType ? [...selectedActionType] : []
	// 	if (checked) {
	// 		newSelectedTypes.push(id)
	// 	} else {
	// 		const index = newSelectedTypes.indexOf(id)
	// 		if (index > -1) newSelectedTypes.splice(index, 1)
	// 	}
	// 	setSelectedActionType(newSelectedTypes.length > 0 ? newSelectedTypes : null)
	// }

	const handleChange = (value: string) => {
		setSelectedActionType([value])
	}

	return (
		<div>
			{/* <Select
				mode='multiple'
				className='table-header-select'
				style={{ width: '100%' }}
				placeholder='Выберите тип акции'
				dropdownRender={menu => (
					<div>
						<Space direction='vertical' style={{ padding: '8px' }}>
							{promotionTypes?.types.map((option: string) => (
								<Checkbox
									key={option}
									checked={selectedActionType?.includes(option) || false}
									onChange={e => handleCheckboxChange(e.target.checked, option)}
								>
									{actionTypes[option] ?? option}
								</Checkbox>
							))}
						</Space>
					</div>
				)}
			/> */}
			<Select
				className='table-header-select'
				style={{
					width: '100%',
					border: selectedActionType !== null ? '1px solid #4c78ee' : '',
				}}
				placeholder='Тип акции'
				value={selectedActionType ? selectedActionType[0] : null}
				onChange={handleChange}
				allowClear
			>
				{promotionTypes?.types.map((option: any) => (
					<Option key={option} value={option}>
						{actionTypes[option] ?? option}
					</Option>
				))}
			</Select>
		</div>
	)
}
