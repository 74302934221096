// Requests
import { api } from '../index'
import { message } from 'antd'

export async function getSettingsData(
	endpoint:
		| 'supplier'
		| 'users'
		| 'tokens'
		| 'wb-user-roles'
		| 'user-personal-data'
		| 'token-scopes'
		| 'token-scopes-name'
		| 'am-i-owner'
		| 'supplier-info'
		| 'get-pvz'
		| null,

	seller_id?: string
) {
	if (endpoint) {
		try {
			const params = new URLSearchParams()

			if (seller_id) {
				params.append('seller_id', seller_id)
			}
			const response = await api.get<any>(`wb-settings/${endpoint}`, { params })
			if (response.data.isSuccess === true) {
				return response.data.data
			} else {
				response.data.isSuccess === false &&
					message.error(`Произошла ошибка на сервере ${response.data.message}`)
			}
		} catch (error: any) {
			console.error(`Error fetching ${endpoint} data:`, error)
			message.error(`Failed to fetch ${endpoint} data. Please try again.`)
		}
	}
}

export async function getUserByPhone(seller_id?: string, phone?: string) {
	try {
		const params = new URLSearchParams()

		if (seller_id) {
			params.append('seller_id', seller_id)
		}
		if (phone) {
			params.append('phone', phone)
		}

		const response = await api.get<any>(`wb-settings/find-user-by-phone`, {
			params,
		})
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching user data:`, error)
		message.error(`Failed to fetch user data. Please try again.`)
	}
}

export async function addUser(
	seller_id: string,
	phone: string,
	position: string
) {
	try {
		const params = new URLSearchParams()

		if (seller_id) {
			params.append('seller_id', seller_id)
		}
		if (phone) {
			params.append('phone', phone)
		}
		if (position) {
			params.append('position', `${position}`)
		}
		const response = await api.post<any>(
			`wb-settings/add-user?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error adding user data:`, error)
		message.error(`Failed to add user data. Please try again.`)
	}
}

export async function deleteUser(seller_id?: string, user_id?: string) {
	try {
		const params = new URLSearchParams()

		if (seller_id) {
			params.append('seller_id', seller_id)
		}
		if (user_id) {
			params.append('user_id', user_id)
		}

		const response = await api.post<any>(
			`wb-settings/delete-user?${params.toString()}`
			// { params }
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error deleting user data:`, error)
		message.error(`Failed to deleting user data. Please try again.`)
	}
}

export async function generateToken(
	seller_id?: string,
	token_name?: string,
	bodyParams?: { param: string[] }
) {
	try {
		const queryParams = new URLSearchParams()

		if (seller_id) {
			queryParams.append('seller_id', seller_id)
		}
		if (token_name) {
			queryParams.append('token_name', token_name)
		}
		if (bodyParams?.param && Array.isArray(bodyParams.param)) {
			const response = await api.post<any>(
				`wb-settings/generate-token`,
				bodyParams,
				{
					params: queryParams,
				}
			)

			return response.data.data.result
		} else {
			throw new Error('Invalid params format')
		}
	} catch (error: any) {
		console.error('Error generating token:', error)
		message.error('Failed to generate token. Please try again.')
	}
}

export async function deleteToken(
	seller_id?: string,
	delete_token_id?: string
) {
	try {
		const params = new URLSearchParams()

		if (seller_id) {
			params.append('seller_id', seller_id)
		}
		if (delete_token_id) {
			params.append('deleted_token_id', delete_token_id)
		}

		const response = await api.post<any>(
			`wb-settings/delete-token`,
			{},
			{
				params,
			}
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error deleting token:`, error)
		message.error(`Failed to delete token. Please try again.`)
	}
}

export async function editUser(
	seller_id: string,
	user_id: string,
	balance_disabled: boolean,
	discount_price_disabled: boolean,
	feedbacks_questions_disabled: boolean,
	finance_disabled: boolean,
	supply_disabled: boolean,
	wb_point_disabled: boolean,
	worker_new_position: string
) {
	try {
		const params = new URLSearchParams()

		params.append('seller_id', seller_id)
		params.append('user_id', user_id)
		params.append('balance_disabled', String(balance_disabled))
		params.append('discount_price_disabled', String(discount_price_disabled))
		params.append(
			'feedbacks_questions_disabled',
			String(feedbacks_questions_disabled)
		)
		params.append('finance_disabled', String(finance_disabled))
		params.append('supply_disabled', String(supply_disabled))
		params.append('wb_point_disabled', String(wb_point_disabled))
		params.append('worker_new_position', worker_new_position)

		const response = await api.patch<any>(
			`wb-settings/edit-user?${params.toString()}`
		)

		return response.data.data
	} catch (error: any) {
		console.error('Error editing user:', error)
		message.error('Не удалось изменить данные пользователя. Попробуйте снова.')
	}
}

export async function editSupplierInfo(seller_id: string, data: any) {
	try {
		const response = await api.patch(
			`/wb-settings/edit-supplier-info?seller_id=${seller_id}`,
			{
				...data,
			}
		)
		return response.data.data
	} catch (error: any) {
		console.error('Error editing supplier info:', error)
		message.error('Не удалось изменить данные компании. Попробуйте снова.')
	}
}
export async function setPvz(seller_id: string) {
	try {
		const response = await api.post(
			`/wb-settings/set-pvz?seller_id=${seller_id}`
		)
		return response.data
	} catch (error: any) {
		console.error('Error editing supplier info:', error)
		message.error('Не удалось изменить данные компании. Попробуйте снова.')
	}
}
