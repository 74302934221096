import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'

const { Option } = Select

interface IIsAvailablePicker {
	showHidden: boolean | null
	setShowHidden: (type: boolean | null) => void
}

const actionTypes = [
	{ value: null, title: 'Все' },
	{ value: true, title: 'Показывать скрытые' },
	{ value: false, title: 'Не показывать скрытые' },
]

export const PickShowHidden: FC<IIsAvailablePicker> = ({
	showHidden,
	setShowHidden,
}) => {
	const handleChange = (value: boolean | null) => {
		setShowHidden(value)
	}

	return (
		<Select
			className='table-header-select'
			style={{
				width: '100%',
				border: showHidden !== null ? '1px solid #4c78ee' : '',
			}}
			value={showHidden}
			placeholder='Выберите тип акции'
			onChange={handleChange}
			filterOption={false}
		>
			{actionTypes.map((option, idx) => (
				<Option key={idx} value={option.value}>
					{option.title}
				</Option>
			))}
		</Select>
	)
}
