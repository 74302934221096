import React from 'react'
import dayjs from 'dayjs'

export const UpdateDate = ({ updatedAt }: any) => {
	return updatedAt ? (
		<span>{dayjs(updatedAt).format('DD.MM.YYYY HH:mm')},</span>
	) : (
		<div>Нет данных</div>
	)
}
