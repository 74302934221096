import {Col, Row} from "antd";
import AdvertisingMirrorTable from "features/advertising-mirror/pages/advertisingMirror/AdvertisingMirrorTable";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import React from "react";

export default function AdvertisingMirrorPage() {

    const location = useLocation();
    const navigate = useNavigate()


    console.log(location.pathname)

    return (
        <Row>
            <Col style={{padding: "10px 20px"}} span={24}>

                <div className={"storage-tabs"}>
                    <div
                        className={`${
                            location.pathname === '/advertising-mirror' ? "tab-active" : ""
                        }`}
                        onClick={() => navigate('/advertising-mirror')}
                    >
                        Зеркало на рекламу
                    </div>
                    <div
                        className={`${
                            location.pathname === '/advertising-mirror/statistics' ? "tab-active" : ""
                        }`}
                        onClick={() => navigate('/advertising-mirror/statistics')}
                    >
                        Рекламная статистика
                    </div>
                    <div
                        className={`${
                            location.pathname.includes('/issuing-positions') ? "tab-active" : ""
                        }`}
                        onClick={() => navigate('/advertising-mirror/issuing-positions')}
                    >
                        Выдача позиций
                    </div>
                    {/*<div*/}
                    {/*    className={`${*/}
                    {/*        location.pathname.includes('/phrase-plus') ? "tab-active" : ""*/}
                    {/*    }`}*/}
                    {/*    onClick={() => navigate('/advertising-mirror/phrase-plus')}*/}
                    {/*>*/}
                    {/*    Фразы плюс*/}
                    {/*</div>*/}
                    <div
                        className={`${
                            location.pathname.includes('/product-by-phrase') ? "tab-active" : ""
                        }`}
                        onClick={() => navigate('/advertising-mirror/product-by-phrase')}
                    >
                        Товары по фразам
                    </div>
                    <div
                        className={`${
                            location.pathname === '/advertising-mirror/bider' ? "tab-active" : ""
                        }`}
                        onClick={() => navigate('/advertising-mirror/bider')}
                    >
                        Бидер
                    </div>
                    <div
                        className={`${
                            location.pathname === '/advertising-mirror/bider-logs' ? "tab-active" : ""
                        }`}
                        onClick={() => navigate('/advertising-mirror/bider-logs')}
                    >
                        События бидера
                    </div>
                </div>
                <Row>
                    <Col span={24}>
                        <Outlet/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
