import { useState, useRef } from 'react'
import { NomenclatureHistory } from './NomenclatureHistory'
import { PromotionsChange } from './PromotionsChange'

interface AnalyticsTableColProps {
	title?: string
	text?: any
	width: string
	backgroundColor?: string
	icon?: any
	isShowHover?: boolean
	hoverData?: Array<Record<string, any>>
	type: 'nomenclatures' | 'promotions'
}

export default function HistoryHover({
	title,
	text,
	width,
	backgroundColor,
	icon,
	isShowHover = false,
	hoverData = [],
	type,
}: AnalyticsTableColProps) {
	const [isHovered, setIsHovered] = useState(false)
	const [hoverDirection, setHoverDirection] = useState<'left' | 'right'>(
		'right'
	)
	const containerRef = useRef<HTMLDivElement>(null)

	const handleMouseEnter = () => {
		if (isShowHover) {
			setIsHovered(true)

			if (containerRef.current) {
				const rect = containerRef.current.getBoundingClientRect()
				const windowWidth = window.innerWidth

				if (rect.right + 300 > windowWidth) {
					setHoverDirection('left')
				} else {
					setHoverDirection('right')
				}
			}
		}
	}

	const handleMouseLeave = () => {
		if (isShowHover) {
			setIsHovered(false)
		}
	}

	return (
		<div
			ref={containerRef}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className='analytics-col'
			style={{ width: width, backgroundColor: backgroundColor }}
		>
			{title && (
				<div className='analytics-col-title'>
					<span>
						{title} {icon}
					</span>
				</div>
			)}
			{isHovered && (
				<div
					className={`hover-info-frame-wrap hover-${hoverDirection}`}
					style={{
						position: 'absolute',
						top: 0,
						[hoverDirection]: '100%',
						maxWidth: '350px',
						zIndex: 10,
						backgroundColor: '#fff',
						border: '1px solid #ddd',
						borderRadius: '4px',
						padding: '10px',
						boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
					}}
				>
					{hoverData.length === 0 ? (
						<div>Нет данных</div>
					) : (
						<div
							style={{
								maxHeight: '200px',
								overflowY: 'auto',
								textAlign: 'left',
								height: '100%',
							}}
						>
							{type === 'nomenclatures' && (
								<NomenclatureHistory hoverData={hoverData} />
							)}

							{type === 'promotions' && (
								<PromotionsChange hoverData={hoverData} />
							)}
						</div>
					)}
				</div>
			)}
		</div>
	)
}
