import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'

const { Option } = Select

interface IIsAvailablePicker {
	selectedAvailableType: boolean | null
	setSelectedAvailableType: (type: boolean | null) => void
}

const actionTypes = [
	{ value: null, title: 'Отслеживаются (все)' },
	{ value: true, title: 'Отслеживается' },
	{ value: false, title: 'Не Отслеживается' },
]

export const PickIsAvailable: FC<IIsAvailablePicker> = ({
	selectedAvailableType,
	setSelectedAvailableType,
}) => {
	const [filteredActionTypes, setFilteredActionTypes] = useState(actionTypes)

	const handleChange = (value: boolean | null) => {
		setSelectedAvailableType(value)
	}

	const handleSearch = (value: string) => {
		const filtered = actionTypes.filter(actionType =>
			actionType.title.toLowerCase().includes(value.toLowerCase())
		)
		setFilteredActionTypes(filtered)
	}

	return (
		<Select
			className='table-header-select'
			style={{ width: '100%' }}
			value={selectedAvailableType}
			placeholder='Выберите тип акции'
			showSearch
			onSearch={handleSearch}
			onChange={handleChange}
			filterOption={false}
		>
			{filteredActionTypes.map((option, idx) => (
				<Option key={idx} value={option.value}>
					{option.title}
				</Option>
			))}
		</Select>
	)
}
