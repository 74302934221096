import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import { useLocation, useNavigate } from 'react-router'
import Icon, { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import useActionsData from './hooks/actions/useActionsData'
import { PickSeller } from './components/PickSeller'
import { PickActionType } from './components/PickActionType'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'
import selectIcon from 'assets/images/select-icon.svg'
import { PickIsAvailable } from './components/PickIsAvailable'
import ActionsTableCol from './components/ActionsTableCol'
import nomenclatureData from './data.json'
import { PickPageSize } from './components/PickPageSize'

export const Nomenclature = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)
	const [selectedActionType, setSelectedActionType] = useState<null | string>(
		null
	)
	const [selectedAvailableType, setSelectedAvailableType] = useState<
		null | boolean
	>(null)
	const [data, setData] = useState<null | any>(nomenclatureData)
	const location = useLocation()
	const navigate = useNavigate()

	const nomenclatureLoading = false //only for dev before api is ready
	const nomenclatureError = false

	const lastSegment = location.pathname.split('/').pop()
	const decodedSegment = decodeURIComponent(lastSegment || '')

	const {
		// nomenclature,
		setPromotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,
		selectedSeller,
		setSelectedSeller,
		sellers,
		// nomenclatureLoading,
		// nomenclatureError,
		getSellersFilterItems,
		getProductsFilterItems,
		searchText,
		setSearchText,
	} = useActionsData()

	const handleCancel = () => {
		setIsEditModalVisible(false)
	}

	// useEffect(() => {
	// 	if (lastSegment) {
	// 		setPromotions(decodedSegment)
	// 		// setPromotions('44%2C%2064')
	// 		setData(nomenclatures)
	// 	}
	// }, [currentPage, lastSegment, nomenclatures])

	const handleClick = (id: number | string) => {
		navigate(`/actions/nomenclature/${id || 0}`)
	}

	const columns = [
		{
			title: 'Название акции',
			dataIndex: 'actionTitle',
			key: 'actionTitle',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		// {
		// 	title: 'Продавец',
		// 	dataIndex: 'seller',
		// 	key: 'seller',
		// 	width: 150,
		// 	align: 'center' as const,
		// 	render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		// },
		{
			title: 'Артикул WB',
			dataIndex: 'wbId',
			key: 'wbId',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		// {
		// 	title: 'Остаток на WB',
		// 	dataIndex: 'wbTotalStorageCount',
		// 	key: 'wbTotalStorageCount',
		// 	width: 50,
		// 	align: 'center' as const,
		// 	render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		// },
		// {
		// 	title: 'На складе продавца',
		// 	dataIndex: 'sellerStock',
		// 	key: 'sellerStock',
		// 	width: 50,
		// 	align: 'center' as const,
		// 	render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		// },
		// {
		// 	title: 'Цена',
		// 	dataIndex: 'price',
		// 	key: 'price',
		// 	width: 50,
		// 	align: 'center' as const,
		// 	render: (price: any, action: any) => (
		// 		<div style={{ paddingLeft: '16px' }}>
		// 			<ActionsTableCol
		// 				text={`${price}₽`}
		// 				width={'100%'}
		// 				isShowHover={true}
		// 				hoverData={{
		// 					'Текущая цена': action?.currentSalePrice,
		// 					'Ожидаемая цена': action?.expectedFinalPrice,
		// 					'Плановая цена': action?.planPrice,
		// 					'Цена до акции': action?.priceBeforeStart,
		// 					'Цена до СПП': action?.currentPriceBeforeSpp,
		// 				}}
		// 			/>
		// 		</div>
		// 	),
		// },
		{
			title: 'Cкидка',
			dataIndex: 'discount',
			key: 'discount',
			width: 50,
			align: 'center' as const,
			render: (discount: any, action: any) => (
				<div style={{ paddingLeft: '16px' }}>
					<ActionsTableCol
						text={`${discount}%`}
						width={'100%'}
						isShowHover={true}
						hoverData={{
							'Текущая скидка': action?.currentDiscount,
							'Плановая скидка': action?.planDiscount,
						}}
					/>
				</div>
			),
		},
		// {
		// 	title: 'Разница',
		// 	dataIndex: 'priceDifference',
		// 	key: 'priceDifference',
		// 	width: 50,
		// 	align: 'center' as const,
		// 	render: (difference: any) => (
		// 		<div style={{ paddingLeft: '16px' }}>{difference.toFixed(2)}₽</div>
		// 	),
		// },
		{
			title: 'Участие',
			dataIndex: 'isParticipating',
			key: 'isParticipating',
			width: 50,
			align: 'center' as const,
			render: (participation: any) => <Checkbox checked={participation} />,
		},
	]

	console.log(data)

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			{/* <Row gutter={6} align={'middle'} justify={'end'}>
				<Col style={{ flexGrow: '1' }}>
					<Input
						className='table-header-input'
						placeholder={'Поиск'}
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
						type={'number'}
					/>
				</Col>
				<Col style={{ width: '250px' }}>
					<PickSeller getSellersFilterItems={getSellersFilterItems} />
				</Col>
				<Col style={{ width: '250px' }}>
					<PickActionType
						selectedActionType={selectedActionType}
						setSelectedActionType={setSelectedActionType}
					/>
				</Col>
				<Col style={{ width: '250px' }}>
					<PickIsAvailable
						selectedAvailableType={selectedAvailableType}
						setSelectedAvailableType={setSelectedAvailableType}
					/>
				</Col>
				<Col style={{ width: '98px' }}>
					<Dropdown
						trigger={['click']}
						placement={'bottomRight'}
						menu={{ items: getProductsFilterItems() }}
						overlayClassName={'dropdown-border'}
					>
						<div className={'analytics-header-dropdown '}>
							<div>Предмет</div>
							<Icon
								component={SelectIconComponent}
								style={{ marginTop: '2px', fontSize: '10px' }}
							/>
						</div>
					</Dropdown>
				</Col>
				<Col style={{ width: '190px' }}>
										<PickPageSize
						currentPageSize={currentPageSize}
						setCurrentPage={setCurrentPage}
						setCurrentPageSize={setCurrentPageSize}
					/>
				</Col>
			</Row> */}

			<Row className='container' style={{ marginTop: '10px' }}>
				{nomenclatureLoading ? (
					<div
						style={{ margin: '0 auto', padding: '150px', textAlign: 'center' }}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : nomenclatureError ? (
					<div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
						Ошибка загрузки данных. Пожалуйста, попробуйте еще раз.
					</div>
				) : (
					<Col span={24}>
						<Col span={24}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: 15,
									padding: 15,
								}}
							>
								<img src={data.data.photo} />
								<p>{data.data.title}</p>
							</div>
						</Col>
						<Table
							columns={columns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={data ? data.data.data : []}
							pagination={false}
							rowKey={(record: any) => record.id}
						/>
						<Col span={24}>
							<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
								<Pagination
									onChange={page => setCurrentPage(page)}
									current={currentPage}
									defaultCurrent={1}
									pageSize={parseInt(currentPageSize)}
									total={data?.total}
									style={{
										textAlign: 'center',
										padding: '16px 0',
									}}
									showSizeChanger={false}
								/>
								<Col style={{ width: '190px' }}>
									<PickPageSize
										currentPageSize={currentPageSize}
										setCurrentPage={setCurrentPage}
										setCurrentPageSize={setCurrentPageSize}
									/>
								</Col>
							</Row>
						</Col>
					</Col>
				)}
			</Row>
		</div>
	)
}
