import { useMutation, useQueryClient } from 'react-query'
import { useCallback, useState } from 'react'
import { backend } from 'api'
import { message } from 'antd'

export const useAddFile = () => {
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<string>('')

	const { mutateAsync: handleAdd, ...options } = useMutation(
		backend.prices.addFileToPrices,
		{
			async onSuccess(data) {
				await Promise.all([
					queryClient.invalidateQueries([`CHART_FIN_RES_DATA`]),
				])
				if (data.isSuccess === false) {
					message.error('Файл не отправлен')
				} else {
					message.success('Файл успешно отправлен')
				}
			},
			async onError() {
				message.error('Файл не отправлен')
			},
		}
	)

	const handleAddFile = useCallback(
		async (values: any) => {
			try {
				const response = await handleAdd(values)
				return response.data
			} catch (e: any) {
				const errorData = e?.response?.data || e?.response?.data?.detail

				if (errorData) {
					setErrorMessage(errorData)
				}
				throw e
			}
		},
		[handleAdd]
	)

	return {
		handleAddFile,
		errorMessage,
		...options,
	}
}
