import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Input,
	Modal,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import {
	DeleteOutlined,
	EditOutlined,
	LoadingOutlined,
	UserAddOutlined,
} from '@ant-design/icons'
import useSettingsData from './hooks/settings/useSettingsData'
import { DeleteModal } from './modals/DeleteModal'
import { EditModal } from './modals/EditModal'
import { AddUserModal } from './modals/AddUser'
import { PickSeller } from './components/PickSeller'

const { Option } = Select

const SettingsMirrorUsersDataTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false)
	const [selectedUser, setSelectedUser] = useState<any>(null)

	const [isOwner, setIsOwner] = useState<boolean>(false)
	const {
		settingsData,
		setDataType,
		selectedSeller,
		setSelectedSeller,
		selectedSellerId,
		sellers,
		isLoading,
		deleteUser,
	} = useSettingsData()
	const { settingsData: isOwnerData, setDataType: setDataTypeOwner } =
		useSettingsData()
	const [data, setData] = useState<any[] | null>(settingsData?.users)

	useEffect(() => {
		setDataType('users')
		setDataTypeOwner('am-i-owner')
		if (settingsData) {
			setData(settingsData?.users)
		}
		if (isOwnerData) {
			setIsOwner(isOwnerData.is_owner)
		}
	}, [settingsData, isOwnerData])

	const showEditModal = (user: any) => {
		setSelectedUser(user)
		setIsEditModalVisible(true)
	}

	const showDeleteModal = (user: any) => {
		setSelectedUser(user)
		setIsDeleteModalVisible(true)
	}

	const handleCancel = () => {
		setIsEditModalVisible(false)
		setIsDeleteModalVisible(false)
		setIsAddUserModalVisible(false)
		setSelectedUser(null)
	}

	const handleDelete = () => {
		if (selectedUser) {
			try {
				deleteUser(selectedUser.id)
				setData((prevData: any) =>
					prevData.filter((user: any) => user.id !== selectedUser.id)
				)
			} catch (error) {
				console.error('Ошибка при удалении пользователя.')
			} finally {
				setIsDeleteModalVisible(false)
				setSelectedUser(null)
			}
		}
	}

	const columns = [
		{
			title: 'Пользователь',
			dataIndex: 'userInfo',
			align: 'center' as const,
			render: (_: any, record: any) => (
				<div style={{ paddingLeft: '1rem' }}>
					<div>{record.firstName}</div>
					<div>{record.phone}</div>
					<div>{record.email}</div>
					<div>{record.position}</div>
				</div>
			),
			width: 250,
		},
		{
			title: 'Финансы',
			dataIndex: 'financeAccess',
			align: 'center' as const,
			render: (_: any, record: any) => (
				<Checkbox checked={!record.access.finance.disabled} />
			),
			width: 150,
		},
		{
			title: 'Цены и скидки',
			dataIndex: 'discountPriceAccess',
			align: 'center' as const,
			render: (_: any, record: any) => (
				<Checkbox checked={!record.access.discountPrice.disabled} />
			),
			width: 150,
		},
		{
			title: 'Отзывы и вопросы',
			dataIndex: 'feedbacksAccess',
			align: 'center' as const,
			render: (_: any, record: any) => (
				<Checkbox checked={!record.access.feedbacksQuestions.disabled} />
			),
			width: 150,
		},
		{
			title: 'Поставки',
			dataIndex: 'supplyAccess',
			align: 'center' as const,
			render: (_: any, record: any) => (
				<Checkbox checked={!record.access.supply.disabled} />
			),
			width: 150,
		},
		{
			title: 'Действие',
			dataIndex: 'action',
			align: 'center' as const,
			render: (_: any, record: any) => (
				<>
					{/*disabled={!isOwner}*/}
					<Button disabled={!isOwner} onClick={() => showEditModal(record)}>
						<EditOutlined />
					</Button>
					<Button disabled={!isOwner} onClick={() => showDeleteModal(record)}>
						<DeleteOutlined />
					</Button>
				</>
			),
			width: 100,
		},
	]

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify={'end'}>
				<Col style={{ width: '250px' }}>
					<PickSeller
						selectedSeller={selectedSeller}
						setSelectedSeller={setSelectedSeller}
					/>
				</Col>
				<Col>
					<Button
						onClick={() => setIsAddUserModalVisible(true)}
						className='table-header-button'
						type='primary'
						disabled={!isOwner}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: '10px',
						}}
					>
						Добавить Пользователя
						<UserAddOutlined style={{ fontSize: '18px', marginLeft: '5px' }} />
					</Button>
				</Col>
			</Row>

			<Row className='container' style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{ margin: '0 auto', padding: '150px', textAlign: 'center' }}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : data?.length === 0 ? (
					<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
				) : (
					<Col span={24}>
						<Table
							columns={columns}
							dataSource={data ? [...data] : []}
							rowKey='id'
							pagination={false}
							scroll={{ x: '100%' }}
						/>
					</Col>
				)}
			</Row>

			<EditModal
				isVisible={isEditModalVisible}
				handleCancel={handleCancel}
				setIsVisible={setIsEditModalVisible}
				selectedUser={selectedUser}
			/>
			<DeleteModal
				handleDelete={handleDelete}
				isVisible={isDeleteModalVisible}
				handleCancel={handleCancel}
				setIsVisible={setIsDeleteModalVisible}
				selected={selectedUser}
				text={`Вы уверены, что хотите удалить пользователя ${selectedUser?.firstName}?`}
			/>
			<AddUserModal
				isVisible={isAddUserModalVisible}
				handleCancel={handleCancel}
				setIsVisible={setIsAddUserModalVisible}
			/>
		</div>
	)
}

export default SettingsMirrorUsersDataTable
