import { renderTableText } from '../helpers/renderTableText'
import { useState } from 'react'

interface AnalyticsTableColProps {
	title?: string
	text?: any
	width: string
	backgroundColor?: string
	icon?: any
	isShowHover?: boolean
	hoverData?: any
}

export default function ActionsTableCol({
	title,
	text,
	width,
	backgroundColor,
	icon,
	isShowHover = false,
	hoverData = {},
}: AnalyticsTableColProps) {
	const [isHovered, setIsHovered] = useState(false)

	const handleMouseEnter = () => {
		if (isShowHover) {
			setIsHovered(true)
		}
	}

	const handleMouseLeave = () => {
		if (isShowHover) {
			setIsHovered(false)
		}
	}

	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className='analytics-col'
			style={{ width: width, backgroundColor: backgroundColor }}
		>
			{title && (
				<div className={'analytics-col-title'}>
					<span>
						{title} {icon}
					</span>
				</div>
			)}

			<div className={'analytics-col-text'}>
				<span
					style={{
						fontSize: '0.88rem',
						color: 'rgba(0, 0, 0, 0.88)',
					}}
				>
					{renderTableText(text)}
				</span>
			</div>
			{isHovered && (
				<div className='hover-info-frame-wrap'>
					{Object.entries(hoverData).length === 0 ? (
						<div>Нет данных</div>
					) : (
						Object.keys(hoverData).map(key => (
							<div key={key}>
								<span style={{ fontWeight: 800 }}>{key}</span>: {hoverData[key]}
							</div>
						))
					)}
				</div>
			)}
		</div>
	)
}
