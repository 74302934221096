import { Col, Row } from 'antd'
import React from 'react'
import { Nomenclature } from '../../features/actions/Nomenclature'

export default function NomenclatureDataPage() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<Nomenclature />
			</Col>
		</Row>
	)
}
