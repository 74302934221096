import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Input,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import { useLocation, useNavigate } from 'react-router'
import Icon, {
	DownloadOutlined,
	EditOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import { PickSeller } from './components/PickSeller'
import { PickActionType } from './components/PickActionType'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'
import { PickIsAvailable } from './components/PickIsAvailable'
import ActionsTableCol from './components/ActionsTableCol'
import { PickIsParticipating } from './components/PickIsParticipating'
import ActionItemDetails from './components/ActionDetailsItem'
import { PickIsNew } from './components/PickIsNew'
import { PickChangeType } from './components/PickChangeType'
import { ReactComponent as rangePickerArrow } from '../../assets/images/rangepicker-arrow.svg'
import HistoryHover from './components/HistoryHover'
import dayjs from 'dayjs'
import { formatDate } from 'utils/formatDate'
import { PickPageSize } from './components/PickPageSize'
import usePricesData from './hooks/prices/usePricesData'
import AddFileModal from './modals/AddFileModal'
const { RangePicker } = DatePicker

export const PricesUpload = () => {
	const [addFileModal, setAddFileModal] = useState(false)

	const [data, setData] = useState<null | any>(null)

	const {
		pricesData,
		promotions,
		setPromotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,

		getSellersFilterItems,
		getProductsFilterItems,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,

		onChangeDate,
		dateSortStart,
		dateSortEnd,
		selectedChangeType,
		setSelectedChangeType,
		selectedSeenType,
		setSelectedSeenType,
		selectedActionType,
		setSelectedActionType,
		selectedSeller,
		isError,
		isLoading,
		setDataType,
	} = usePricesData()

	const columns = [
		{
			title: '',
			dataIndex: 'photoUrl',
			key: 'photoUrl',
			width: '5%',
			align: 'center' as const,
			render: (photo: string, record: any) => {
				const { wbArticle, linkOnSite } = record || {}
				return (
					<div
						className='analytics-col-title__wrap'
						style={{ padding: '5px 12px 5px 0' }}
					>
						<CopyToClipboard
							text={`${wbArticle || ''}`}
							onCopy={() => message.success('Текст скопирован')}
						>
							<div
								className='analytics-col-title'
								style={{ cursor: 'pointer' }}
							>
								{wbArticle || 'Нет данных'}
							</div>
						</CopyToClipboard>
						<a href={linkOnSite} target='_blank' rel='noopener noreferrer'>
							<img
								src={photo}
								style={{
									width: '90px',
									minHeight: 120,
								}}
								alt='Товар'
							/>
						</a>
						{/* {category && <div className='analytics-col-title'>{category}</div>} */}
					</div>
				)
			},
		},
		{
			title: 'Продавец',
			dataIndex: 'companyName',
			key: 'companyName',
			width: '7%',
			height: 120,
			align: 'center' as const,
			render: (text: string) => <div style={{ paddingLeft: 16 }}>{text}</div>,
		},
		{
			title: 'Категория',
			dataIndex: 'category',
			key: 'category',
			width: 150,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Текущая цена',
			dataIndex: 'currentPrice',
			key: 'currentPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Нормальная цена',
			dataIndex: 'normalPrice',
			key: 'normalPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Минимальная цена',
			dataIndex: 'minPrice',
			key: 'minPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Желаемая цена',
			dataIndex: 'desiredPrice',
			key: 'desiredPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ paddingLeft: '16px' }}>{text}</div>,
		},
		{
			title: 'Текущая скидка',
			dataIndex: 'inPromoActionsTotal',
			key: 'inPromoActionsTotal',
			width: '7%',
			align: 'center' as const,
			render: (text: string) => <div style={{ paddingLeft: 16 }}>{text}</div>,
		},
		{
			title: 'Будущая цена',
			dataIndex: 'newPrice',
			key: 'newPrice',
			width: '7%',
			align: 'center' as const,
			render: (text: string) => <div style={{ paddingLeft: 16 }}>{text}</div>,
		},
		{
			title: 'Будущая скидка',
			dataIndex: 'newDiscount',
			key: 'newDiscount',
			width: '7%',
			align: 'center' as const,
			render: (text: string) => <div style={{ paddingLeft: 16 }}>{text}</div>,
		},
		{
			title: 'Цена на WB',
			dataIndex: 'participationPercentage',
			key: 'participationPercentage',
			width: '7%',
			align: 'center' as const,
			render: (text: string) => <div style={{ paddingLeft: 16 }}>{text}</div>,
		},
	]
	console.log(data)
	return (
		<>
			<Row
				gutter={6}
				align={'middle'}
				justify={'end'}
				style={{ gap: 5, background: 'transparent', marginTop: 10 }}
			>
				<Col>
					<Button
						href='https://it-korobka.ru/api/storage/prices/new_price_sample.xlsx'
						type={'primary'}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<DownloadOutlined />
						Скачать шаблон
					</Button>
				</Col>

				<Col>
					<Button
						onClick={() => setAddFileModal(true)}
						type={'primary'}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						Добавить файл продуктов
					</Button>
				</Col>
			</Row>

			<Row className='container' style={{ height: '100%', marginTop: 10 }}>
				{isLoading ? (
					<div
						style={{ margin: '0 auto', padding: '150px', textAlign: 'center' }}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : isError ? (
					<div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
						Ошибка загрузки данных. Пожалуйста, попробуйте еще раз.
					</div>
				) : (
					<Col span={24}>
						<Table
							columns={columns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={data ? data : []}
							pagination={false}
							rowKey={(record: any) => record.id}
							tableLayout='fixed'
							scroll={{
								x: 'max-content',
								y: '65vh',
								// y: '76dvh',
							}}
							size='small'
							style={{ width: '100%' }}
						/>

						<Col span={24}>
							<Pagination
								onChange={page => setCurrentPage(page)}
								current={currentPage}
								defaultCurrent={1}
								pageSize={parseInt(currentPageSize)}
								total={data?.total}
								style={{
									textAlign: 'center',
									padding: '16px 0',
								}}
								showSizeChanger={false}
							/>
						</Col>
					</Col>
				)}
			</Row>

			<AddFileModal
				selectedSeller={selectedSeller}
				onClose={() => setAddFileModal(false)}
				isOpen={addFileModal}
				setData={setData}
			/>
		</>
	)
}
