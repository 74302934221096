import {api, apiKiz} from "index";
import dayjs from "dayjs";

// Requests
export async function getAdvertisingMirrorData(
    sellerId?: string,
    offSet?: string,
    pageSize?: string,
    selectedTab?: string,
    selectedType?: string,
) {
    let params = new URLSearchParams();


    if (pageSize) {
        params.append("limit", pageSize);
    }
    if (offSet) {
        params.append("offset", offSet);
    }

    if (selectedTab === '0') {

    } else {
        if (selectedTab != null) {
            params.append("status", selectedTab);
        }
    }
    if (selectedType === '0') {

    } else {
        if (selectedType != null) {
            params.append("type", selectedType);
        }
    }

    if (sellerId) {
        params.append("seller_id", sellerId);
        const response = await api.get<any>(`/ads-campaigns/adverts`, {
            params,
        });
        return response.data.data;
    }

}

// Requests
export async function getIssuingPositionsData(
    dateStart: string,
    dateEnd: string,
    currentPage?: string,
    currentPageSize?: number,
    sellers?: string,
    products?: string[],
    type?: string,
    debouncedSearchArticle?: string,
    isWithElementsInTable?: boolean,
    isPlusMinusPhraseMode?: boolean,
) {
    let params = new URLSearchParams();

    if (currentPage) {
        params.append("page", currentPage);
    }

    if (currentPageSize) {
        params.append("limit", currentPageSize.toString());
    }

    if (type) {
        params.append("type", type.toString());
    }

    // @ts-ignore
    params.append("without_empty", isWithElementsInTable);
    // @ts-ignore
    params.append("by_plus_minus_phrases", isPlusMinusPhraseMode);

    if (debouncedSearchArticle) {
        params.append("article", debouncedSearchArticle);
    }

    if (dateStart && dateEnd) {
        params.append("period_start_date", dayjs(dateStart).format("DD.MM.YYYY HH:mm"));
        params.append("period_end_date", dayjs(dateEnd).format("DD.MM.YYYY HH:mm"));
    }

    if (products && products.length > 0) {
        params.append("categories_ids", products?.map((item: any) => item?.id)?.join(","));
    }

    if (sellers) {
        params.append("seller_id", sellers);

        const response = await api.get<any>(`/keywordsV2/products/stats`, {
            params,
        });
        return response.data.data;
    }
}


export async function getBiderData(
    currentPage?: string,
    currentPageSize?: number,
    sellers?: string,
    products?: string[],
    debouncedSearchArticle?: string,
) {
    let params = new URLSearchParams();

    if (currentPage) {
        params.append("page", currentPage);
    }

    if (currentPageSize) {
        params.append("limit", currentPageSize.toString());
    }

    if (debouncedSearchArticle) {
        params.append("article", debouncedSearchArticle);
    }

    if (products && products.length > 0) {
        params.append("categories_ids", products?.map((item: any) => item?.id)?.join(","));
    }

    if (sellers) {
        params.append("seller_id", sellers);

        const response = await api.get<any>(`/bider/stats`, {
            params,
        });
        return response.data.data;
    }
}

export async function getBiderLogsData(
    currentPage?: string,
    currentPageSize?: number,
    sellers?: string,
    debouncedSearchArticle?: string,
) {
    let params = new URLSearchParams();

    if (currentPage) {
        params.append("page", currentPage);
    }

    if (currentPageSize) {
        params.append("limit", currentPageSize.toString());
    }

    if (debouncedSearchArticle) {
        params.append("article", debouncedSearchArticle);
    }

    if (sellers) {
        params.append("seller_id", sellers);

        const response = await api.get<any>(`/bider/logs`, {
            params,
        });
        return response.data.data;
    }
}

export async function changeStatusOfProduct(body: any) {

    const {inAds, id, datetime} = body;
    let params = new URLSearchParams();

    params.append("in_ads", String(!!inAds));

    // if (datetime){
    //     params.append("start_time", datetime);
    // }

    if (id) {
        const response = await api.post<any>(`/productsV2/${id}/ads`, {},{params});
        return response.data.data;
    }
}

export async function getAdvertisingMirrorDataCount(
    selectedSeller?: string
) {

    let params = new URLSearchParams();

    if (selectedSeller) {
        params.append("seller_id", selectedSeller);
        const response = await api.get<any>(`/ads-campaigns/count`, {params});
        return response.data.data;
    }


}

export async function mirrorStartStatus(
    id: string
) {
    let params = new URLSearchParams();
    params.append("id", id);
    console.log('here' + id)

    if (id) {
        const response = await api.get<any>(`/ads-campaignsadverts/${id}/start`);
        return response.data.data;
    }
}

export async function mirrorPauseStatus(
    id: string
) {
    let params = new URLSearchParams();
    params.append("id", id);

    if (id) {
        const response = await api.get<any>(`/ads-campaignsadverts/${id}/pause`);
        return response.data.data;
    }
}

export async function mirrorStopStatus(
    id: string
) {
    let params = new URLSearchParams();
    params.append("id", id);

    if (id) {
        const response = await api.get<any>(`/ads-campaignsadverts/${id}/stop`);
        return response.data.data;
    }
}

export async function getStatsData(
	dateStart: string,
	dateEnd: string,
	currentPage?: string,
	currentPageSize?: number,
	sellers?: string,
	products?: string[],
	debouncedSearchArticle?: string,
) {
	let params = new URLSearchParams()

	if (currentPage) {
		params.append('page', currentPage)
	}

	if (currentPageSize) {
		params.append('limit', currentPageSize.toString())
	}


	if (debouncedSearchArticle) {
		params.append('article', debouncedSearchArticle)
	}

	if (dateStart && dateEnd) {
		params.append(
			'period_start_date',
			dayjs(dateStart).format('DD.MM.YYYY HH:mm')
		)
		params.append('period_end_date', dayjs(dateEnd).format('DD.MM.YYYY HH:mm'))
	}

	if (products && products.length > 0) {
		params.append(
			'categories_ids',
			products?.map((item: any) => item?.id)?.join(',')
		)
	}

	if (sellers) {
		params.append('seller_id', sellers)

		const response = await api.get<any>(`/keywordsV2/advertising/stats`, {
			params,
		})
		return response.data.data
	}
}