import React, { FC } from 'react'
import { Select, Checkbox, Space } from 'antd'
import useActionsData from '../hooks/prices/usePricesData'

const { Option } = Select

interface ITypePicker {
	selectedType: any[] | null
	setSelectedType: (type: string[] | null) => void
	data: any
	title: string
}

export const PickChangeType: FC<ITypePicker> = ({
	selectedType,
	setSelectedType,
	data,
	title,
}) => {
	const handleCheckboxChange = (checked: boolean, id: string) => {
		const newSelectedTypes = selectedType ? [...selectedType] : []
		if (checked) {
			newSelectedTypes.push(id)
		} else {
			const index = newSelectedTypes.indexOf(id)
			if (index > -1) newSelectedTypes.splice(index, 1)
		}
		console.log(selectedType)
		setSelectedType(newSelectedTypes.length > 0 ? newSelectedTypes : null)
	}

	return (
		<div>
			<Select
				mode='multiple'
				className='table-header-select'
				style={{ width: '100%' }}
				placeholder={title}
				dropdownRender={menu => (
					<div>
						<Space
							direction='vertical'
							style={{
								padding: '8px',
								width: '100%',
								maxHeight: 300,
								overflowY: 'auto',
								overflowX: 'hidden',
							}}
						>
							{data?.map((option: any) => (
								<Checkbox
									key={option.id}
									checked={selectedType?.includes(option.id) || false}
									onChange={e =>
										handleCheckboxChange(e.target.checked, option.id)
									}
								>
									{option.title || option.name}
								</Checkbox>
							))}
						</Space>
					</div>
				)}
			/>
		</div>
	)
}
