import React, { useState } from 'react'
import { Col, message, Modal, Row } from 'antd'
import Icon from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ReactComponent as editIcon } from 'assets/images/edit-icon.svg'
import { renderTableText } from '../helpers/renderTableText'

import StatisticsTableCol from './StatisticsTableCol'
import StatisticsTableNote from './StatisticsTableNote'
import DynamicTable from './DynamicTable'

export const AdvertisingStatisticsItem = ({ item }: any) => {
	const [articleModal, setArticleModal] = useState<any>({
		productId: '',
		analyticsUserId: '',
		articule: '',
		isUpdate: false,
		isOpen: false,
	})

	const [costPriceModal, setCostPriceModal] = useState<any>({
		productId: '',
		isOpen: false,
	})

	const [adLeftMoneyModal, setAdLeftMoneyModal] = useState<any>({
		productId: '',
		isOpen: false,
	})

	const priceWithDiscountWb = (
		Number(item?.currentFullPrice) -
		(Number(item?.currentFullPrice) * Number(item?.discount)) / 100
	)?.toFixed(2)
	const priceWithDiscountSpp = (
		Number(priceWithDiscountWb) -
		(Number(priceWithDiscountWb) * item?.spp) / 100
	).toFixed(2)

	const statisticsData = [
		[
			{
				title: 'Выкуп',
				text: item.deliveryRub,
				width: '6%',
				backgroundColor: '#FFFFFF',
			},
		],
		[
			{
				title: 'Цена',
				text: item.deliveryRub,
				width: '6%',
				backgroundColor: '#E6E7E8',
			},
		],
		[
			{
				title: 'Всего по складам',
				text: item.totalStocks,
				width: '6%',
				backgroundColor: '#FFFFFF',
			},
		],
		[
			{
				title: 'Прибыль',
				text: item.deliveryRub,
				width: '6%',
				backgroundColor: '#E6E7E8',
			},

			// {
			// 	title: 'Остаток рекл бюджета, руб',
			// 	text: item.adLeftMoney,
			// 	width: '8%',
			// 	backgroundColor: '#E6E7E8',
			// 	icon: (
			// 		<Icon
			// 			component={editIcon}
			// 			style={{ cursor: 'pointer' }}
			// 			onClick={() =>
			// 				setAdLeftMoneyModal({
			// 					...adLeftMoneyModal,
			// 					productId: item?.productId,
			// 					isOpen: true,
			// 				})
			// 			}
			// 		/>
			// 	),
			// },
			// {
			// 	title: 'Текущая цена, руб',
			// 	text: priceWithDiscountSpp,
			// 	width: '10%',
			// 	backgroundColor: '#E6E7E8',
			// 	isShowHover: true,
			// 	hoverData: {
			// 		'Полная цена': item?.currentFullPrice,
			// 		'Цена со скидкой ВБ': priceWithDiscountWb,
			// 		'Цена со скидкой СПП': priceWithDiscountSpp,
			// 	},
			// },
			// {
			// 	title: 'Рекомендованная цена, руб',
			// 	text: item.recommendedPrice,
			// 	width: '9%',
			// 	backgroundColor: '#E6E7E8',
			// 	isShowHover: true,
			// 	hoverData: {
			// 		'Рекомендованная цена': item?.recommendedPrice || '-',
			// 		'Предел изменения до': item?.autoChangeLt || '-',
			// 		'Предел изменения после': item?.autoChangeGt || '-',
			// 	},
			// 	icon: <Icon component={editIcon} style={{ cursor: 'pointer' }} />,
			// },
		],
	]

	const data = [
		{
			key: '1',
			name: 'Заказы',
			avg: 4.5,
			'2024-12-01': 5,
			'2024-12-02': 3.5,
		},
		{
			key: '2',
			name: 'Прибыль',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '3',
			name: 'Маржинальность магазина',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '4',
			name: 'ДРР магазина (заказы)',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '5',
			name: 'Маржинальность артикула',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '6',
			name: 'Цена без СПП',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '7',
			name: 'Заказы',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '8',
			name: 'Корзины',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '9',
			name: 'Показы (реклама)',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '10',
			name: 'Затраты (реклама)',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '11',
			name: 'Клики (реклама)',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '12',
			name: 'Все переходы',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '13',
			name: 'CTR',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '14',
			name: 'CR1 Корзина',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '15',
			name: 'CR2 Заказ',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '16',
			name: 'CPC',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '17',
			name: 'CPO',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '18',
			name: 'ДРР (заказы)',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
		{
			key: '19',
			name: 'ДРР (выкупы)',
			avg: 3.2,
			'2024-12-01': 4,
			'2024-12-02': 3,
		},
	]

	const dates = ['2024-12-01', '2024-12-02']

	const StatisticsRow = ({ data, index }: any) => {
		return (
			<div className={`analytics-row ${index % 2 === 0 ? '' : 'gray'}`}>
				{data.map((col: any, index: number) => (
					<StatisticsTableCol
						key={index}
						title={col?.title}
						text={col?.text}
						width={col?.width}
						backgroundColor={col?.backgroundColor}
						icon={col?.icon}
						isShowHover={col?.isShowHover}
						hoverData={col?.hoverData}
					/>
				))}
			</div>
		)
	}

	return (
		<Row className={'analytics-row-container'} gutter={1}>
			<Col
				className={'gray'}
				style={{
					padding: '9px',
					textAlign: 'center',
					width: '108px',
					borderRadius: '8px 0 0 8px',
				}}
			>
				<div className={'analytics-col-title__wrap'}>
					<CopyToClipboard
						text={`${item?.wbArticule}`}
						onCopy={() => message.success('Текст скопирован')}
					>
						<div
							className={'analytics-col-title'}
							style={{
								cursor: 'pointer',
							}}
						>
							{item?.wbArticule}
						</div>
					</CopyToClipboard>
					<a href={item?.linkOnSite} target='_blank'>
						<img
							src={item.photo}
							style={{ width: '100%', margin: '9px 0', minHeight: 120 }}
						/>
					</a>
					{item?.status !== '-' && (
						<div className={'analytics-col-title'}>{item?.status}</div>
					)}
					{item?.action && (
						<div className={'analytics-col-title'}>{item?.action}</div>
					)}
				</div>
			</Col>
			<Col>
				{statisticsData?.map((item: any, index: number) => (
					<StatisticsRow data={item} index={index} />
				))}
			</Col>
			<Col className={'ads-statistics-right-wrapper'}>
				<DynamicTable data={data} />
			</Col>
		</Row>
	)
}
