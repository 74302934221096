import { Col, Row } from 'antd'
import { ActionsChangesTable } from 'features/actions/ActionsChangesTable'
import React from 'react'

export default function ActionsChanges() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<ActionsChangesTable />
			</Col>
		</Row>
	)
}
