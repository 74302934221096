import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Select, Spin } from 'antd'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import useSettingsData from './hooks/settings/useSettingsData'
import { EditModal } from './modals/EditSupplierModal'
import SettingItem from './components/SettingsSuppliesItem'
import { PickSeller } from './components/PickSeller'

const { Option } = Select

const SettingsMirrorSellersDataTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)
	const [selectedUser, setSelectedUser] = useState<any>(null)

	const [isOwner, setIsOwner] = useState<boolean>(false)
	const {
		settingsData,
		setDataType,
		selectedSeller,
		setSelectedSeller,
		sellers,
		isLoading,
	} = useSettingsData()

	const { settingsData: isOwnerData, setDataType: setDataTypeOwner } =
		useSettingsData()

	const [data, setData] = useState<{} | null>(settingsData?.data.supplierInfo)

	useEffect(() => {
		setDataType('supplier-info')
		setDataTypeOwner('am-i-owner')
		console.log(settingsData)
		if (settingsData) {
			console.log(settingsData)
			setData(settingsData.data.supplierInfo)
		}
		if (isOwnerData) {
			setIsOwner(isOwnerData.is_owner)
		}
	}, [settingsData, isOwnerData])

	const handleCancel = () => {
		setIsEditModalVisible(false)
		setSelectedUser(null)
	}
	console.log('data', data)

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify={'end'}>
				<Col style={{ width: '250px' }}>
					<PickSeller
						selectedSeller={selectedSeller}
						setSelectedSeller={setSelectedSeller}
					/>
				</Col>
				<Col>
					<Button
						onClick={() => setIsEditModalVisible(true)}
						className='table-header-button'
						type='primary'
						disabled={!isOwner}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: '10px',
						}}
					>
						Изменить продавца
						<EditOutlined style={{ fontSize: '18px', marginLeft: '5px' }} />
					</Button>
				</Col>
			</Row>

			<Row className='container' style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{ margin: '0 auto', padding: '150px', textAlign: 'center' }}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : !data ? (
					<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
				) : (
					<Col span={24}>
						<div className='document-page-items'>
							<SettingItem item={data} />
						</div>
					</Col>
				)}
			</Row>

			<EditModal
				isVisible={isEditModalVisible}
				handleCancel={handleCancel}
				setIsVisible={setIsEditModalVisible}
				selectedSupplier={data}
			/>
		</div>
	)
}

export default SettingsMirrorSellersDataTable
