import { formatDate } from 'utils/formatDate'
import { api } from '../index'
import { message } from 'antd'

export async function getPricesData(
	dataType?: 'prices' | 'history',
	sellers?: any[],
	search?: string,
	available?: boolean | null,
	products?: any[],
	currentPageSize?: string,
	page?: string | number
) {
	try {
		const params = new URLSearchParams()

		if (sellers && sellers.length > 0) {
			params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
		}

		if (available !== null) {
			params.append('tracking', `${available}`)
		}
		if (products && products.length > 0) {
			params.append(
				'categories',
				products?.map((item: any) => item?.id)?.join(',')
			)
		}
		if (page) {
			params.append('page', `${page}`)
		}

		if (currentPageSize) {
			params.append('limit', currentPageSize)
		}

		if (search) {
			params.append('search', search)
		}

		const response = await api.get<any>(
			`prices/${dataType}?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Failed to fetch data. Please try again.`)
	}
}

export async function addFileToPrices(file?: any) {
	// const { seller_id, step, file, filename } = file

	const formData = new FormData()
	formData.append('file', file)

	const params = new URLSearchParams()


	console.log('params')
	console.log(params)

	const response = await api.post<any>(`/prices/import-prices`, formData, {
		params,
	})
	return response.data
}
