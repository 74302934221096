import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { useNavigate, Link, useLocation, Outlet } from 'react-router-dom'

const PricesPage = () => {
	const location = useLocation()
	const navigate = useNavigate()
	return (
		<Row>
			<Col style={{ padding: '10px 20px' }} span={24}>
				<div className={'storage-tabs'}>
					<div
						className={`${
							location.pathname.includes('/current') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/prices/current')}
					>
						Текущие
					</div>
					<div
						className={`${
							location.pathname.includes('/upload') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/prices/upload')}
					>
						Загрузка
					</div>
					<div
						className={`${
							location.pathname.includes('/history') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/prices/history')}
					>
						История
					</div>
					<div
						className={`${
							location.pathname.includes('/planned') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/prices/planned')}
					>
						Запланированные цены
					</div>
				</div>
				<Row>
					<Col span={24}>
						<Outlet />
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default PricesPage
