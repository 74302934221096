import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'

const { Option } = Select

interface IIsAvailablePicker {
	selectedSeenType: boolean | null
	setSelectedSeenType: (type: boolean | null) => void
}

const actionTypes = [
	{ value: null, title: 'Просмотрен (все)' },
	{ value: false, title: 'Просмотрен' },
	{ value: true, title: 'Не просмотрен' },
]

export const PickIsNew: FC<IIsAvailablePicker> = ({
	selectedSeenType,
	setSelectedSeenType,
}) => {
	const handleChange = (value: boolean | null) => {
		setSelectedSeenType(value)
	}

	return (
		<Select
			className='table-header-select'
			style={{
				width: '100%',
				border: selectedSeenType !== null ? '1px solid #4c78ee' : '',
			}}
			value={selectedSeenType}
			placeholder='Выберите тип акции'
			onChange={handleChange}
			filterOption={false}
		>
			{actionTypes.map((option, idx) => (
				<Option key={idx} value={option.value}>
					{option.title}
				</Option>
			))}
		</Select>
	)
}
