import React, { useEffect, useState } from 'react'
import {
	Button,
	Col,
	DatePicker,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import ActionItem from './components/ActionItem'
import dayjs from 'dayjs'
import useActionsData from './hooks/actions/useActionsData'
import Icon, {
	DeleteOutlined,
	EditOutlined,
	KeyOutlined,
	LoadingOutlined,
	MoreOutlined,
	UserAddOutlined,
} from '@ant-design/icons'
import selectIcon, {
	ReactComponent as SelectIconComponent,
} from '../../assets/images/select-icon.svg'
import { EditModal } from './modals/EditModal'
import { DeleteModal } from './modals/DeleteModal'
import { AddUserModal } from './modals/AddUser'
import { AddTokenModal } from './modals/AddToken'
import { PickSeller } from './components/PickSeller'
import { ReactComponent as rangePickerArrow } from '../../assets/images/rangepicker-arrow.svg'
import { PickActionType } from './components/PickActionType'
import { PickShowHidden } from './components/PickShowHidden'

const { RangePicker } = DatePicker
const { Option } = Select

const ActionsCalendar = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
	const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false)
	const [selectedToken, setSelectedToken] = useState<any>(null)

	const {
		actionsData,
		selectedSeller,
		setSelectedSeller,
		sellers,
		setDataType,
		isLoading,
		onChangeDate,
		dateSortStart,
		dateSortEnd,
		getSellersFilterItems,
		selectedActionType,
		setSelectedActionType,
		showHidden,
		setShowHidden,
		setHideAction,
		setLoadingCheckboxId,
		loadingCheckboxId,
	} = useActionsData()
	const { setDataType: setDataNamesType, actionsData: scopesNamesData } =
		useActionsData()

	const [data, setData] = useState(actionsData)
	console.log(data)
	useEffect(() => {
		setDataType('calendar')
		// setDataNamesType('token-scopes-name')
		if (actionsData) {
			setData(actionsData)
		}
	}, [actionsData])

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify='end'>
				<Col style={{ width: '220px' }}>
					<PickShowHidden
						showHidden={showHidden}
						setShowHidden={setShowHidden}
					/>
				</Col>
				<Col style={{ width: '120px' }}>
					<PickSeller
						getSellersFilterItems={getSellersFilterItems}
						selectedSeller={selectedSeller}
					/>
				</Col>
				<Col style={{ width: '230px' }}>
					<PickActionType
						selectedActionType={selectedActionType}
						setSelectedActionType={setSelectedActionType}
					/>
				</Col>
				<Col>
					<RangePicker
						onChange={onChangeDate}
						placeholder={['Начало', 'Конец']}
						separator={<Icon component={rangePickerArrow} />}
						format={'DD.MM.YYYY'}
						value={[dateSortStart, dateSortEnd]}
					/>
				</Col>
				{/* <Col>
					<Button
						onClick={() => setIsAddUserModalVisible(true)}
						className='table-header-button'
						type='primary'
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: '10px',
						}}
					>
						Добавить токен
						<KeyOutlined style={{ fontSize: '18px', marginLeft: '5px' }} />
					</Button>
				</Col> */}
			</Row>

			<Row className={'container'} style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{
							margin: '0 auto',
							padding: '150px',
							textAlign: 'center',
						}}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : data?.length === 0 ? (
					<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
				) : (
					<>
						<Col span={24}>
							{data &&
								data?.map((item: any, index: number) => (
									<ActionItem
										item={item}
										onCheck={setHideAction}
										setLoadingCheckboxId={setLoadingCheckboxId}
										loadingCheckboxId={loadingCheckboxId}
										key={index}
									/>
								))}
						</Col>
					</>
				)}
			</Row>
		</div>
	)
}

export default ActionsCalendar
