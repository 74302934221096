import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'

const { Option } = Select

interface IIsAvailablePicker {
	selectedParticipatingType: boolean | null
	setSelectedParticipatingType: (type: boolean | null) => void
}

const actionTypes = [
	{ value: null, title: 'Участвуют (все)' },
	{ value: true, title: 'Участвует' },
	{ value: false, title: 'Не участвует' },
]

export const PickIsParticipating: FC<IIsAvailablePicker> = ({
	selectedParticipatingType,
	setSelectedParticipatingType,
}) => {
	const handleChange = (value: boolean | null) => {
		setSelectedParticipatingType(value)
	}

	return (
		<Select
			className='table-header-select'
			style={{ width: '100%' }}
			value={selectedParticipatingType}
			placeholder='Выберите тип акции'
			onChange={handleChange}
			filterOption={false}
		>
			{actionTypes.map((option, idx) => (
				<Option key={idx} value={option.value}>
					{option.title}
				</Option>
			))}
		</Select>
	)
}
