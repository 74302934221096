import { useQuery, useMutation, useQueryClient } from 'react-query'
import { backend } from 'api'
import React, { useEffect, useState } from 'react'
import { Checkbox, Input, MenuProps, message } from 'antd'
import useSellerList from 'features/sellers/hooks/useSellerList'
import { format } from 'path'
import { formatDate } from 'utils/formatDate'
import dayjs, { Dayjs } from 'dayjs'
import useProductList from 'features/product/hooks/useProductList'
import Icon from '@ant-design/icons'
import { ReactComponent as searchIcon } from 'assets/images/search-icon.svg'
import { useDebounce } from 'use-debounce'

export default function usePricesData() {
	const queryClient = useQueryClient()
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [currentPageSize, setCurrentPageSize] = useState<string>('25')

	const [dataType, setDataType] = useState<'prices' | 'history'>('prices')
	const [pricesData, setPricesData] = useState<any>(null)
	const [nomenclaturesData, setNomenclaturesData] = useState<any>(null)
	const [promotions, setPromotions] = useState<string[] | null>(null)
	const [searchText, setSearchText] = useState<string>('')
	const [selectedChangeType, setSelectedChangeType] = useState<string[] | null>(
		null
	)
	const [selectedSeenType, setSelectedSeenType] = useState<boolean | null>(null)

	const [debouncedSearchText] = useDebounce(searchText, 1000)

	const {
		sellers,
		search: searchSellers,
		setSearch: setSearchSellers,
	} = useSellerList()

	const [selectedSeller, setSelectedSeller] = useState<Array<string>>([])

	const [dateSortStart, setDateSortStart] = useState<Dayjs | null>(null)
	const [dateSortEnd, setDateSortEnd] = useState<Dayjs | null>(null)

	const onChangeDate = (dates: [Dayjs | null, Dayjs | null] | null) => {
		if (dates) {
			setDateSortStart(dates[0])
			setDateSortEnd(dates[1])
		} else {
			setDateSortStart(null)
			setDateSortEnd(null)
		}
	}

	useEffect(() => {
		const today = dayjs()
		const startDate = today.subtract(14, 'day')
		const endDate = today.add(14, 'day')

		onChangeDate([startDate, endDate])
	}, [])

	const formatDateForApi = (date: Dayjs | null) =>
		date ? date.format('DD.MM.YYYY') : null

	const [selectedProducts, setSelectedProducts] = useState<Array<string>>([])
	const [selectedAvailableType, setSelectedAvailableType] = useState<
		null | boolean
	>(null)
	const [selectedParticipatingType, setSelectedParticipatingType] = useState<
		null | boolean
	>(null)
	const [selectedActionType, setSelectedActionType] = useState<null | string[]>(
		null
	)

	const {
		products,
		search: searchProduct,
		setSearch: setSearchProduct,
		setNotEmptyMode,
	} = useProductList()

	const {
		data: sData,
		error,
		isLoading,
		refetch,
		...options
	} = useQuery(
		[
			'PRICES_DATA',
			dataType,
			selectedSeller,
			debouncedSearchText,
			selectedParticipatingType,
			selectedProducts,
			currentPageSize,
			currentPage,
		],
		() =>
			backend.prices.getPricesData(
				dataType,
				selectedSeller,
				debouncedSearchText,
				selectedParticipatingType,
				selectedProducts,
				currentPageSize,
				currentPage
			),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled:
				!!selectedSeller && !!dateSortStart && !!dateSortEnd && !!dataType,

			onError: err => {
				console.error(`Failed to fetch ${dataType} data: ${err}`)
			},
			onSuccess: data => {
				const newSettingsData = data?.result ? data.result : data
				setPricesData(newSettingsData)
			},
		}
	)

	const getProductsFilterItems = () => {
		if (!products) {
			return []
		}

		let allItems: any[] = []
		let selectedItems: any[] = []

		products?.forEach((item: any) => {
			const isDuplicate = selectedProducts.some(
				(productItem: any) =>
					productItem?.id?.toString() === item?.id?.toString()
			)

			if (!isDuplicate) {
				allItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item.title}
								checked={selectedProducts.some(
									(productItems: any) =>
										productItems?.id?.toString() === item?.id?.toString()
								)}
								onChange={e => {
									let newSelectedProducts = [...selectedProducts]
									if (e.target.checked) {
										newSelectedProducts.push(item)
									} else {
										newSelectedProducts = newSelectedProducts.filter(
											(el: any) => el?.id?.toString() !== item?.id?.toString()
										)
									}
									setSelectedProducts(newSelectedProducts)
								}}
							>
								{item.title}
							</Checkbox>
						</div>
					),
					key: `product-${item.id}`,
				})
			}
		})

		selectedProducts?.forEach((item: any) => {
			const isDuplicate = selectedProducts.some(
				(productItem: any) =>
					productItem?.id?.toString() === item?.id?.toString()
			)

			if (isDuplicate) {
				selectedItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item.title}
								checked={selectedProducts.some(
									(productItems: any) =>
										productItems?.id?.toString() === item?.id?.toString()
								)}
								onChange={e => {
									let newSelectedProducts = [...selectedProducts]
									if (e.target.checked) {
										newSelectedProducts.push(item)
									} else {
										newSelectedProducts = newSelectedProducts.filter(
											(el: any) => el?.id?.toString() !== item?.id?.toString()
										)
									}
									setSelectedProducts(newSelectedProducts)
								}}
							>
								{item.title}
							</Checkbox>
						</div>
					),
					key: `product-${item.id}`,
				})
			}
		})

		const items: MenuProps['items'] = [
			{
				label: (
					<Input
						value={searchProduct}
						className={'analytic-dropdown-input'}
						onClick={e => e?.stopPropagation()}
						onChange={e => setSearchProduct(e?.target?.value)}
						prefix={<Icon component={searchIcon} />}
						placeholder={'Поиск...'}
					/>
				),
				key: 'product-search',
			},
			...selectedItems,
			...allItems,
		]

		return items
	}

	const getSellersFilterItems = () => {
		if (!sellers) {
			return []
		}

		let allItems: any[] = []
		let selectedItems: any[] = []

		sellers?.forEach((item: any) => {
			const isDuplicate = selectedSeller.some(
				(sellerItem: any) => sellerItem?.id.toString() === item?.id.toString()
			)

			if (!isDuplicate) {
				allItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item?.company_name}
								checked={selectedSeller.some(
									(sellerItem: any) =>
										sellerItem?.id.toString() === item?.id.toString()
								)}
								onChange={e => {
									let newSelectedSellers = [...selectedSeller]
									if (e.target.checked) {
										newSelectedSellers.push(item)
									} else {
										newSelectedSellers = newSelectedSellers.filter(
											(el: any) => el?.id.toString() !== item?.id.toString()
										)
									}
									setSelectedSeller(newSelectedSellers)
								}}
							>
								{item?.company_name}
							</Checkbox>
						</div>
					),
					key: `seller-${item.id.toString()}`,
				})
			}
		})

		selectedSeller?.forEach((item: any) => {
			const isDuplicate = selectedSeller.some(
				(sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString()
			)

			if (isDuplicate) {
				selectedItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item?.company_name}
								checked={selectedSeller.some(
									(sellerItem: any) =>
										sellerItem?.id.toString() === item?.id.toString()
								)}
								onChange={e => {
									let newSelectedSellers = [...selectedSeller]
									if (e.target.checked) {
										newSelectedSellers.push(item)
									} else {
										newSelectedSellers = newSelectedSellers.filter(
											(el: any) => el?.id.toString() !== item?.id.toString()
										)
									}
									setSelectedSeller(newSelectedSellers)
								}}
							>
								{item?.company_name}
							</Checkbox>
						</div>
					),
					key: `seller-${item.id}`,
				})
			}
		})

		const items: MenuProps['items'] = [
			{
				label: (
					<Input
						value={searchSellers}
						className={'analytic-dropdown-input'}
						onClick={e => e?.stopPropagation()}
						onChange={e => setSearchSellers(e?.target?.value)}
						prefix={<Icon component={searchIcon} />}
						placeholder={'Поиск...'}
					/>
				),
				key: 'sizes-search',
			},
			...selectedItems,
			...allItems,
		]

		return items
	}

	// const [loadingCheckboxId, setLoadingCheckboxId] = useState<
	// 	string | number | null
	// >(null)

	// const setInAction = useMutation(
	// 	async ({
	// 		id,
	// 		setInAction,
	// 	}: {
	// 		id: string | number
	// 		setInAction: boolean
	// 	}) => await backend.prices.setInAction(id, setInAction),
	// 	{
	// 		onSuccess: () => {
	// 			queryClient.invalidateQueries('prices_NOMENCLATURES_DATA')
	// 		},
	// 		onSettled: () => {
	// 			setLoadingCheckboxId(null)
	// 		},
	// 	}
	// )

	return {
		pricesData,
		nomenclatures: nomenclaturesData,
		selectedSeller,
		setSelectedSeller,
		dataType,
		setDataType,
		sellers,
		setSearchSellers,
		searchSellers,
		isLoading,
		error,

		setPromotions,
		promotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,

		dateSortStart,
		dateSortEnd,
		onChangeDate,

		getProductsFilterItems,
		getSellersFilterItems,

		searchText,
		setSearchText,

		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,
		selectedActionType,
		setSelectedActionType,
		// setAvailable: setInAction.mutate,
		// setLoadingCheckboxId,
		// loadingCheckboxId,

		selectedChangeType,
		setSelectedChangeType,
		selectedSeenType,
		setSelectedSeenType,

		...options,
	}
}
