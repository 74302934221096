import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
	Tooltip,
} from 'antd'
import { useLocation, useNavigate } from 'react-router'
import Icon, {
	EditOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import useActionsData from './hooks/actions/useActionsData'
import { PickSeller } from './components/PickSeller'
import { PickActionType } from './components/PickActionType'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'
import selectIcon from 'assets/images/select-icon.svg'
import { PickIsAvailable } from './components/PickIsAvailable'
import ActionsTableCol from './components/ActionsTableCol'
import { PickIsParticipating } from './components/PickIsParticipating'
import { PickPageSize } from './components/PickPageSize'
import { UpdateDate } from './components/UpdateDate'
import HistoryHover from './components/HistoryHover'
import { ConfirmationModal } from './modals/ConfirmationModal'
import { PickIsSetInAction } from './components/PickIsSetInAction'

const { Option } = Select

const ActionItemsTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)

	const [data, setData] = useState<null | any>(null)
	const location = useLocation()
	const navigate = useNavigate()

	const lastSegment = location.pathname.split('/').pop()
	const decodedSegment = decodeURIComponent(lastSegment || '')

	const {
		nomenclatures,
		setPromotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,
		selectedSeller,
		setSelectedSeller,
		sellers,
		nomenclaturesLoading,
		nomenclaturesError,
		getSellersFilterItems,
		getProductsFilterItems,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,
		setAvailable,
		setLoadingCheckboxId,
		loadingCheckboxId,
		setAllInAction,
		isSetInAction,
		setIsSetInAction,
	} = useActionsData()

	const handleCancel = () => {
		setIsEditModalVisible(false)
	}

	useEffect(() => {
		if (lastSegment) {
			setPromotions([decodedSegment])
			// setPromotions('44%2C%2064')
			setData(nomenclatures)
		}
	}, [currentPage, lastSegment, nomenclatures])

	const handleClick = (id: number | string) => {
		navigate(`/actions/nomenclature/${id || 0}`)
	}

	const [confirmUnsetAllInActionVisible, setConfirmUnsetAllInActionVisible] =
		useState(false)
	const [confirmSetAllInActionVisible, setConfirmSetAllInActionVisible] =
		useState(false)

	const [confirmUnsetInActionVisible, setConfirmUnsetInActionVisible] =
		useState(false)
	const [confirmSetInActionVisible, setConfirmSetInActionVisible] =
		useState(false)

	const handleSetAllUnavailable = () => {
		if (selectedSeller.length !== 1) {
			message.error('Выберите одного продавца')
		} else {
			setConfirmUnsetAllInActionVisible(true)
		}
	}
	const handleSetAllAvailable = () => {
		if (selectedSeller.length !== 1) {
			message.error('Выберите одного продавца')
		} else {
			setConfirmSetAllInActionVisible(true)
		}
	}

	const handleSetUnavailable = () => {
		setConfirmUnsetInActionVisible(true)
	}
	const handleSetAvailable = () => {
		setConfirmSetInActionVisible(true)
	}

	const confirmSetAllInAction = () => {
		setAllInAction({ setInAction: true, promotion: decodedSegment })
	}

	const confirmUnsetAllInAction = () => {
		setAllInAction({ setInAction: false, promotion: decodedSegment })
	}

	const confirmSetInAction = () => {
		// setAllInAction({ setInAction: true, promotion: decodedSegment })
	}

	const confirmUnsetInAction = () => {
		// setAllInAction({ setInAction: false, promotion: decodedSegment })
	}

	const columns = [
		{
			title: '',
			dataIndex: 'photoUrl',
			key: 'photoUrl',
			width: '5%',
			align: 'center' as const,
			render: (photo: string, record: any) => {
				const { wbArticle, linkOnSite } = record || {}
				return (
					<div
						className='analytics-col-title__wrap'
						style={{ padding: '5px 12px 5px 0' }}
					>
						<CopyToClipboard
							text={`${wbArticle || ''}`}
							onCopy={() => message.success('Текст скопирован')}
						>
							<div
								className='analytics-col-title'
								style={{ cursor: 'pointer' }}
							>
								{wbArticle || 'Нет данных'}
							</div>
						</CopyToClipboard>
						<a href={linkOnSite} target='_blank' rel='noopener noreferrer'>
							<img
								src={photo}
								style={{
									width: '90px',
									minHeight: 120,
								}}
								alt='Товар'
							/>
						</a>
						{/* {category && <div className='analytics-col-title'>{category}</div>} */}
					</div>
				)
			},
		},
		{
			title: (
				<Tooltip title='Отслеживание'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Отслеживание
					</span>
				</Tooltip>
			),
			dataIndex: 'isAvailable',
			key: 'isAvailable',
			width: '3%',
			align: 'center' as const,
			overflow: 'hidden',
			display: '-webkit-box',
			textOverflow: 'ellipsis',
			WebkitBoxOrient: 'vertical',
			WebkitLineClamp: 3,
			render: (isAvailable: any) => (
				<div
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						background: isAvailable
							? 'rgba(0, 203, 68, 0.6)'
							: 'rgba(255, 203, 10, 0.6)',
					}}
				>
					{isAvailable ? 'Да' : 'Нет'}
				</div>
			),
		},
		{
			title: 'Участие',
			dataIndex: 'inAction',
			key: 'inAction',
			width: '3%',
			align: 'center' as const,
			render: (participation: any) => (
				<div
					style={{
						padding: '0 8px',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						background: participation
							? 'rgba(0, 203, 68, 0.6)'
							: 'rgba(255, 203, 10, 0.6)',
					}}
				>
					{participation ? 'Да' : 'Нет'}
				</div>
			),
		},
		{
			title: 'Предмет',
			dataIndex: 'category',
			key: 'category',
			width: '5%',
			align: 'center' as const,
			render: (text: any, action: any) => (
				<div
					onClick={() => handleClick(action.id)}
					className='item-title'
					style={{
						padding: '0 8px',
						fontSize: '0.88rem',
						fontWeight: 400,
						cursor: 'pointer',
					}}
				>
					{text}
				</div>
			),
		},
		{
			title: 'Продавец',
			dataIndex: 'seller',
			key: 'seller',
			width: 100,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},

		{
			title: (
				<Tooltip title='Текущая розничная цена'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Текущая розн. цена
					</span>
				</Tooltip>
			),
			dataIndex: 'currentSalePrice',
			key: 'currentSalePrice',
			width: 50,
			align: 'center' as const,
			render: (price: any, action: any) => (
				<div style={{ padding: '0 8px' }}>{price}</div>
			),
		},
		// 'Текущая цена': action?.currentSalePrice,
		// 'Ожидаемая цена': action?.expectedFinalPrice,
		// 'Плановая цена': action?.planPrice,
		// 'Цена до акции': action?.priceBeforeStart,
		// 'Цена до СПП': action?.,
		{
			title: (
				<Tooltip title='Текущая скидка %'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Текущая скидка %
					</span>
				</Tooltip>
			),
			dataIndex: 'currentDiscount',
			key: 'currentDiscount',
			width: 50,
			align: 'center' as const,
			render: (discount: any, action: any) => (
				<div style={{ padding: '0 8px' }}>{`${discount}%`}</div>
			),
		},
		{
			title: (
				<Tooltip title='Текущая до СПП сейчас'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Текущая до СПП сейчас
					</span>
				</Tooltip>
			),
			dataIndex: 'currentPriceBeforeSpp',
			key: 'currentPriceBeforeSpp',
			width: 50,
			align: 'center' as const,
			render: (price: any, action: any) => {
				return {
					props: {
						style: {
							background: 'rgba(0, 203, 68, 0.6)',
						},
					},
					children: <div style={{ padding: '0 8px' }}>{price}</div>,
				}
			},
		},
		{
			title: (
				<Tooltip title='Загружаемая скидка для участия в акции'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Загружаемая скидка для уч. в акции
					</span>
				</Tooltip>
			),
			dataIndex: 'planDiscount',
			key: 'planDiscount',
			width: 50,
			align: 'center' as const,
			render: (discount: any, action: any) => (
				<div style={{ padding: '0 8px' }}>{`${discount}%`}</div>
			),
		},
		{
			title: (
				<Tooltip title='Цена до СПП для участия в акции'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Цена до СПП для уч. в акции
					</span>
				</Tooltip>
			),
			dataIndex: 'expectedFinalPrice',
			key: 'expectedFinalPrice',
			width: 50,
			align: 'center' as const,
			render: (price: any, action: any) => {
				return {
					props: {
						style: {
							background: 'rgba(0, 203, 68, 0.6)',
						},
					},
					children: <div style={{ padding: '0 8px' }}>{price}</div>,
				}
			},
		},
		{
			title: (
				<Tooltip title='Разница'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Разница
					</span>
				</Tooltip>
			),
			dataIndex: 'priceDifference',
			key: 'priceDifference',
			width: 50,
			align: 'center' as const,
			render: (difference: any, action: any) => {
				return {
					props: {
						style: {
							background: 'rgba(255, 203, 10, 0.6)',
						},
					},
					children: (
						<div style={{ padding: '0 8px' }}>{difference.toFixed(2)}₽</div>
					),
				}
			},
		},
		{
			title: 'Остаток WB',
			dataIndex: 'wbTotalStorageCount',
			key: 'wbTotalStorageCount',
			width: 50,
			align: 'center' as const,
			render: (text: any, action: any) => (
				<div style={{ padding: '0 8px' }}>
					<ActionsTableCol
						text={text}
						width={'100%'}
						isShowHover={true}
						hoverData={action.wbStocksBySizes}
					/>
				</div>
			),
		},
		{
			title: (
				<Tooltip title='На складе продавца'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						На складе продавца
					</span>
				</Tooltip>
			),
			dataIndex: 'ownTotalStorageCount',
			key: 'ownTotalStorageCount',
			width: 50,
			align: 'center' as const,
			render: (text: any, action: any) => (
				<div style={{ padding: '0 8px' }}>
					<ActionsTableCol
						text={text}
						width={'100%'}
						isShowHover={true}
						hoverData={action.ownStocksBySizes}
					/>
				</div>
			),
		},
		{
			title: (
				<Tooltip title='Средняя скорость заказа за 3 дня'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Средняя ск. заказа за 3 дня
					</span>
				</Tooltip>
			),
			dataIndex: 'avgOrdersPerDay',
			key: 'avgOrdersPerDay',
			width: 50,
			align: 'center' as const,
			render: (value: any, action: any) => (
				<div style={{ padding: '0 8px' }}>{value}</div>
			),
		},
		{
			title: 'Изменения',
			dataIndex: 'history',
			key: 'history',
			width: 50,
			align: 'center' as const,
			render: (history: any, action: any) => {
				if (!history || history.length === 0) {
					return <div>Нет данных</div>
				}

				const [latest, previous] = history

				return (
					<div style={{ padding: '0 8px' }}>
						<HistoryHover
							title=' '
							width='100%'
							icon={<InfoCircleOutlined size={40} />}
							isShowHover={true}
							hoverData={history}
							type='nomenclatures'
						/>
						{history.length > 1 && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 7,
									width: '100%',
								}}
							>
								<UpdateDate updatedAt={latest?.updatedAt} />
								{previous?.planDiscount !== latest?.planDiscount && (
									<span>
										<span style={{ fontWeight: 600 }}>Скидка:</span>{' '}
										{previous?.planDiscount}% → {latest?.planDiscount}%
									</span>
								)}
								{previous?.inAction !== latest?.inAction && (
									<div>
										<span>{previous?.inAction ? 'У' : 'Не у'}частвует</span>→
										<span>
											{latest?.inAction ? '' : 'Не'} участвует в акции,
										</span>
									</div>
								)}
								{/* {previous?.isNew !== latest?.isNew && (
									<div>
										<span>{previous?.isNew ? 'П' : 'Не п'}росмотрен,</span>
										<span> → {latest?.isNew ? '' : 'Не '}просмотрен</span>
									</div>
								)} */}
							</div>
						)}
					</div>
				)
			},
		},
		{
			title: (
				<Tooltip title='Ставим в акцию'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Ставим в акцию
					</span>
				</Tooltip>
			),
			dataIndex: 'setInAction',
			key: 'setInAction',
			width: 50,
			align: 'center' as const,
			render: (setIn: any, action: any) => (
				<Checkbox
					checked={setIn}
					disabled={loadingCheckboxId === action.id}
					onChange={() => {
						setLoadingCheckboxId(action.id)
						setAvailable({
							id: action.id,
							setInAction: !setIn,
						})
					}}
				>
					{loadingCheckboxId === action.id && <Spin size='small' />}
				</Checkbox>
			),
		},
		{
			title: (
				<Tooltip title='Участие в акции'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Участие в акции
					</span>
				</Tooltip>
			),
			dataIndex: 'setInAction',
			key: 'setInAction',
			width: '8%',
			align: 'center' as const,
			render: (setIn: any, action: any) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '5px',
					}}
				>
					<Button type='primary' onClick={handleSetAvailable}>
						Участвовать
					</Button>
					<Button type='primary' onClick={handleSetUnavailable}>
						Не участвовать
					</Button>
				</div>
			),
		},
	]

	return (
		<>
			<div style={{ backgroundColor: '#f8f8f8' }}>
				<Row gutter={6} align={'middle'} justify={'end'} style={{ gap: 5 }}>
					<Col style={{ flexGrow: '1' }}>
						<Input
							className='table-header-input'
							placeholder={'Поиск'}
							value={searchText}
							onChange={e => setSearchText(e.target.value)}
							type={'number'}
						/>
					</Col>
					<Col
						style={{
							width: '335px',
							paddingTop: 0,
							paddingBottom: 0,
						}}
					>
						<Button
							type='primary'
							onClick={handleSetAllAvailable}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Поставить все товары продавца в акцию
						</Button>
					</Col>
					<Col style={{ width: '315px', paddingTop: 0, paddingBottom: 0 }}>
						<Button
							type='primary'
							onClick={handleSetAllUnavailable}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Убрать все товары продавца из акции
						</Button>
					</Col>
					<Col style={{ width: '120px' }}>
						<PickSeller
							getSellersFilterItems={getSellersFilterItems}
							selectedSeller={selectedSeller}
						/>
					</Col>
					<Col style={{ width: '180px' }}>
						<PickIsAvailable
							selectedAvailableType={selectedAvailableType}
							setSelectedAvailableType={setSelectedAvailableType}
						/>
					</Col>
					<Col style={{ width: '140px' }}>
						<PickIsParticipating
							selectedParticipatingType={selectedParticipatingType}
							setSelectedParticipatingType={setSelectedParticipatingType}
						/>
					</Col>
					<Col style={{ width: '140px' }}>
						<PickIsSetInAction
							isSetInAction={isSetInAction}
							setIsSetInAction={setIsSetInAction}
						/>
					</Col>
					<Col style={{ width: '98px' }}>
						<Dropdown
							trigger={['click']}
							placement={'bottomRight'}
							menu={{ items: getProductsFilterItems() }}
							overlayClassName={'dropdown-border'}
						>
							<div className={'analytics-header-dropdown '}>
								<div>Предмет</div>
								<Icon
									component={SelectIconComponent}
									style={{ marginTop: '2px', fontSize: '10px' }}
								/>
							</div>
						</Dropdown>
					</Col>

					{/* <Col>
						<Button
							onClick={() => setIsEditModalVisible(true)}
							className='table-header-button'
							type='primary'
							// disabled={!isOwner}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginLeft: '10px',
							}}
						>
							Изменить продавца
							<EditOutlined style={{ fontSize: '18px', marginLeft: '5px' }} />
						</Button>
					</Col> */}
				</Row>

				<Row className='container' style={{ marginTop: '10px' }}>
					{nomenclaturesLoading ? (
						<div
							style={{
								margin: '0 auto',
								padding: '150px',
								textAlign: 'center',
							}}
						>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
						</div>
					) : nomenclaturesError ? (
						<div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
							Ошибка загрузки данных. Пожалуйста, попробуйте еще раз.
						</div>
					) : (
						<Col span={24}>
							<Table
								columns={columns}
								locale={{
									emptyText: 'Нет данных',
									filterTitle: 'Фильтр',
									filterReset: 'Очистить',
									filterEmptyText: 'Нет фильтров',
								}}
								dataSource={data ? data.data : []}
								pagination={false}
								rowKey={(record: any) => record.id}
								scroll={{
									x: 'max-content',
									y: '65vh',
								}}
								size='small'
								style={{ width: '100%' }}
								// style={{ minHeight: '780px', height: '68dvh' }}
							/>
							<Col span={24}>
								<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
									<Pagination
										onChange={page => setCurrentPage(page)}
										current={currentPage}
										defaultCurrent={1}
										pageSize={parseInt(currentPageSize)}
										total={data?.total}
										style={{
											textAlign: 'center',
											padding: '16px 0',
										}}
										showSizeChanger={false}
									/>
									<Col style={{ width: '190px' }}>
										<PickPageSize
											currentPageSize={currentPageSize}
											setCurrentPage={setCurrentPage}
											setCurrentPageSize={setCurrentPageSize}
										/>
									</Col>
								</Row>
							</Col>
						</Col>
					)}
				</Row>
			</div>
			<ConfirmationModal
				isOpen={confirmSetAllInActionVisible}
				setIsOpen={setConfirmSetAllInActionVisible}
				confirm={confirmSetAllInAction}
				title={
					'Вы уверены, что хотите поставить все товары выбранного продавца в акцию?'
				}
			/>
			<ConfirmationModal
				isOpen={confirmUnsetAllInActionVisible}
				setIsOpen={setConfirmUnsetAllInActionVisible}
				confirm={confirmUnsetAllInAction}
				title={
					'Вы уверены, что хотите убрать все товары выбранного продавца из акции?'
				}
			/>
			<ConfirmationModal
				isOpen={confirmSetInActionVisible}
				setIsOpen={setConfirmSetInActionVisible}
				confirm={confirmSetInAction}
				title={'Вы уверены, что хотите поставить этот товар в акцию?'}
			/>
			<ConfirmationModal
				isOpen={confirmUnsetInActionVisible}
				setIsOpen={setConfirmUnsetInActionVisible}
				confirm={confirmUnsetInAction}
				title={'Вы уверены, что хотите убрать этот товар из акции?'}
			/>
		</>
	)
}

export default ActionItemsTable
