import dayjs from 'dayjs'
import { renderTableText } from '../helpers/renderTableText'
import { useState, useRef, useEffect } from 'react'

interface AnalyticsTableColProps {
	title?: string
	text?: any
	width: string
	backgroundColor?: string
	icon?: any
	isShowHover?: boolean
	hoverData?: Array<Record<string, any>> // Ожидается массив объектов
	type: 'nomenclatures' | 'promotions'
}

export default function HistoryHover({
	title,
	text,
	width,
	backgroundColor,
	icon,
	isShowHover = false,
	hoverData = [],
	type,
}: AnalyticsTableColProps) {
	const [isHovered, setIsHovered] = useState(false)
	const [hoverDirection, setHoverDirection] = useState<'left' | 'right'>(
		'right'
	)
	const containerRef = useRef<HTMLDivElement>(null)

	const handleMouseEnter = () => {
		if (isShowHover) {
			setIsHovered(true)

			// Проверяем, есть ли место справа
			if (containerRef.current) {
				const rect = containerRef.current.getBoundingClientRect()
				const windowWidth = window.innerWidth

				if (rect.right + 300 > windowWidth) {
					// 300 - предполагаемая ширина выпадающего окна
					setHoverDirection('left')
				} else {
					setHoverDirection('right')
				}
			}
		}
	}

	const handleMouseLeave = () => {
		if (isShowHover) {
			setIsHovered(false)
		}
	}

	return (
		<div
			ref={containerRef}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className='analytics-col'
			style={{ width: width, backgroundColor: backgroundColor }}
		>
			{title && (
				<div className='analytics-col-title'>
					<span>
						{title} {icon}
					</span>
				</div>
			)}
			{isHovered && (
				<div
					className={`hover-info-frame-wrap hover-${hoverDirection}`}
					style={{
						position: 'absolute',
						top: 0,
						[hoverDirection]: '100%',
						maxWidth: '350px',
						zIndex: 10,
						backgroundColor: '#fff',
						border: '1px solid #ddd',
						borderRadius: '4px',
						padding: '10px',
						boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
					}}
				>
					{hoverData.length === 0 ? (
						<div>Нет данных</div>
					) : (
						<div
							style={{
								maxHeight: '200px',
								overflowY: 'auto',
								textAlign: 'left',
								height: '100%',
							}}
						>
							{hoverData.map((data, index) => (
								<div key={index}>
									<div style={{ fontWeight: 'bold' }}>
										Изменение {index + 1}:
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection:
												type === 'nomenclatures' ? 'row' : 'column',
											gap: 7,
										}}
									>
										{data?.updatedAt ? (
											<span>
												{dayjs(data.updatedAt)
													.format('DD.MM.YYYY HH:mm')}
												,
											</span>
										) : (
											<div>Нет данных</div>
										)}
										{type === 'nomenclatures' && (
											<>
												<span>
													{data.inAction ? 'У' : 'Не у'}частвует в акции,
												</span>
												<span>
													<span style={{ fontWeight: 600 }}>Скидка</span>:{' '}
													{data.planDiscount}%
												</span>
											</>
										)}
										{type === 'promotions' && (
											<>
												<span>
													<span style={{ fontWeight: 500 }}>
														Процент Участия
													</span>
													: {data.participationPercentage}%,
												</span>
												<span>
													<span style={{ fontWeight: 500 }}>
														Остатки по акциям
													</span>
													: {data.inPromoActionsLeftovers},
												</span>
												<span>
													<span style={{ fontWeight: 500 }}>
														Всего товаров в акциях
													</span>
													: {data.participationPercentage},
												</span>
												<span>
													<span style={{ fontWeight: 500 }}>
														Всего товаров не в акциях
													</span>
													: {data.notInPromoActionsTotal},
												</span>
												<span>
													<span style={{ fontWeight: 500 }}>
														Процент Участия
													</span>
													: {data.participationPercentage}%
												</span>
											</>
										)}
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	)
}
