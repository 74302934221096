import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'

const { Option } = Select

interface IIsAvailablePicker {
	isSetInAction: boolean | null
	setIsSetInAction: (type: boolean | null) => void
}

const actionTypes = [
	{ value: null, title: 'Поставлен в акцию (все)' },
	{ value: true, title: 'Поставлен' },
	{ value: false, title: 'Не поставлен' },
]

export const PickIsSetInAction: FC<IIsAvailablePicker> = ({
	isSetInAction,
	setIsSetInAction,
}) => {
	const handleChange = (value: boolean | null) => {
		setIsSetInAction(value)
	}

	return (
		<Select
			className='table-header-select'
			style={{
				width: '100%',
				border: isSetInAction !== null ? '1px solid #4c78ee' : '',
			}}
			value={isSetInAction}
			placeholder='Выберите тип акции'
			onChange={handleChange}
			filterOption={false}
		>
			{actionTypes.map((option, idx) => (
				<Option key={idx} value={option.value}>
					{option.title}
				</Option>
			))}
		</Select>
	)
}
