import { Col, Row } from 'antd'
import { ActionsDetailsTable } from 'features/actions/ActionsDetailsTable'
import React from 'react'

export default function ActionsDetails() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<ActionsDetailsTable />
			</Col>
		</Row>
	)
}
