import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
	Tooltip,
} from 'antd'
import { useLocation, useNavigate } from 'react-router'
import Icon, {
	EditOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import useActionsData from './hooks/actions/useActionsData'
import { PickSeller } from './components/PickSeller'
import { PickActionType } from './components/PickActionType'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'
import { PickIsAvailable } from './components/PickIsAvailable'
import ActionsTableCol from './components/ActionsTableCol'
import { PickIsParticipating } from './components/PickIsParticipating'
import ActionItemDetails from './components/ActionDetailsItem'
import { PickIsNew } from './components/PickIsNew'
import { PickChangeType } from './components/PickChangeType'
import { ReactComponent as rangePickerArrow } from '../../assets/images/rangepicker-arrow.svg'
import HistoryHover from './components/HistoryHover'
import dayjs from 'dayjs'
import { formatDate } from 'utils/formatDate'
import { PickPageSize } from './components/PickPageSize'
import { UpdateDate } from './components/UpdateDate'
import { ConfirmationModal } from './modals/ConfirmationModal'
const { RangePicker } = DatePicker

const changeTypeColors = [
	{
		id: 1,
		color: 'rgba(0, 203, 68, 0.6)',
	},
	{
		id: 2,
		color: 'rgba(255, 203, 10, 0.6)',
	},
	{
		id: 3,
		color: 'rgba(255, 50, 50, 0.6)',
	},
	{
		id: 4,
		color: 'rgba(25, 73, 230, 0.4)',
	},
	{
		id: 5,
		color: 'rgba(255, 123, 10, 0.6)',
	},
	{
		id: 6,
		color: 'rgba(25, 252, 210, 0.6)',
	},
	{
		id: 7,
		color: 'rgba(200, 54, 0, 0.6)',
	},
	{
		id: 8,
		color: 'rgba(255, 52, 210, 0.6)',
	},
]

const actionTypes: any = {
	regular: { title: 'Акция', color: 'rgba(70, 130, 180, 0.6)' },
	auto: { title: 'Автоакция', color: 'rgba(255, 203, 10, 0.6)' },
	special: {
		title: 'Специальное предложение',
		color: 'rgba(34, 139, 34, 0.6)',
	},
	sale: { title: 'Дни распродаж', color: 'rgba(255, 10, 255, 0.6)' },
}

export const ActionsDetailsTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)

	const [data, setData] = useState<null | any>(null)
	const location = useLocation()
	const navigate = useNavigate()

	const {
		setDataType,
		promotionChanges,
		promotionChangeTypesData,
		actionsData,
		promotions,
		setPromotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,
		nomenclaturesLoading,
		nomenclaturesError,
		getSellersFilterItems,
		getProductsFilterItems,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,
		setPromotionChangeSeen,
		setLoadingCheckboxId,
		loadingCheckboxId,
		onChangeDate,
		dateSortStart,
		dateSortEnd,
		selectedChangeType,
		setSelectedChangeType,
		selectedSeenType,
		setSelectedSeenType,
		selectedActionType,
		setSelectedActionType,
		selectedSeller,
		setAllPromotionChangeSeen,
	} = useActionsData()

	const handleCancel = () => {
		setIsEditModalVisible(false)
	}

	useEffect(() => {
		setDataType('promotion_changes')
		if (promotionChanges) {
			setData(promotionChanges)
		}
	}, [promotionChanges])

	useEffect(() => {
		if (!selectedChangeType || selectedChangeType.length < 1) {
			setSelectedChangeType(['2', '3', '4', '5', '6', '7', '8'])
		}
	}, [selectedChangeType])

	const handleGoToAction = (id: number | string) => {
		navigate(`/actions/actions-calendar/${id || 0}`)
	}

	const [confirmUnsetAllSeenVisible, setConfirmUnsetAllSeenVisible] =
		useState(false)
	const [confirmSetAllSeenVisible, setConfirmSetAllSeenVisible] =
		useState(false)

	const handleSetAllNotSeen = () => {
		// if (selectedSeller.length < 1) {
		// 	message.error('Выберите хотя бы одного продавца')
		// } else {
		setConfirmUnsetAllSeenVisible(true)
		// }
	}
	const handleSetAllSeen = () => {
		// if (selectedSeller.length < 1) {
		// 	message.error('Выберите хотя бы одного продавца')
		// } else {
		setConfirmSetAllSeenVisible(true)
		// }
	}

	const confirmSetAllSeen = () => {
		setAllPromotionChangeSeen({ seen: true })
	}

	const confirmUnsetAllSeen = () => {
		setAllPromotionChangeSeen({ seen: false })
	}

	const columns = [
		{
			title: 'Название акции',
			dataIndex: 'promotionName',
			key: 'promotionName',
			width: '10%',
			height: 120,
			align: 'center' as const,
			render: (text: string, action: any) => (
				<div
					className='item-title'
					style={{
						padding: '0 8px',
						fontSize: '0.88rem',
						fontWeight: 400,
						cursor: 'pointer',
					}}
					onClick={() => handleGoToAction(action.promotionId)}
				>
					{text}
				</div>
			),
		},
		{
			title: 'Продавец',
			dataIndex: 'companyName',
			key: 'companyName',
			width: '7%',
			height: 120,
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: 'Тип акции',
			dataIndex: 'type',
			key: 'type',
			width: '7%',
			height: 120,
			align: 'center' as const,
			render: (type: string) => (
				<div
					style={{
						backgroundColor: actionTypes[type].color,
						borderRadius: '5px',
						padding: '1px 3px',
					}}
				>
					<p style={{ color: '#303030', margin: 0 }}>
						{actionTypes[type].title}
					</p>
				</div>
			),
		},
		{
			title: 'Начало - окончание',
			dataIndex: 'endDateTime',
			key: 'endDateTime',
			width: '10%',
			align: 'center' as const,
			render: (text: string, dates: any) => {
				const actionDates = `${formatDate(
					new Date(dates.startDate)
				)} - ${formatDate(new Date(dates.endDate))}`
				return (
					<div
						style={{
							padding: '0 8px',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							style={{
								width: '100%',
								minWidth: 180,
								maxWidth: 180,
							}}
						>
							{actionDates}
						</div>
					</div>
				)
			},
		},
		{
			title: 'Остатки по акциям',
			dataIndex: 'inPromoActionsLeftovers',
			key: 'inPromoActionsLeftovers',
			width: '4%',
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: 'Всего товаров в акциях',
			dataIndex: 'inPromoActionsTotal',
			key: 'inPromoActionsTotal',
			width: '5%',
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: 'Остатки не по акциям',
			dataIndex: 'notInPromoActionsLeftovers',
			key: 'notInPromoActionsLeftovers',
			width: '5%',
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: (
				<Tooltip title='Всего товаров не участвующих в акциях'>
					<span
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 3,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'normal',
						}}
					>
						Всего товаров не участвующих в акциях
					</span>
				</Tooltip>
			),
			dataIndex: 'notInPromoActionsTotal',
			key: 'notInPromoActionsTotal',
			width: '6%',
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: 'Процент участия',
			dataIndex: 'participationPercentage',
			key: 'participationPercentage',
			width: '4.3%',
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}%</div>,
		},
		{
			title: 'Тип изменения',
			dataIndex: 'changeType',
			key: 'changeType',
			width: '8%',
			align: 'center' as const,
			render: (changeType: any, action: any) => {
				const colorData = changeTypeColors.find(item => item.id === changeType)
				const color = colorData ? colorData.color : 'rgba(255, 255, 255, 0)'
				const titleData = promotionChangeTypesData.find(
					(item: any) => item.id === changeType
				)
				const title = titleData ? titleData.title : 'Неизвестный тип'
				return (
					<Tooltip title={title}>
						<div
							style={{
								display: 'flex',
								width: '100%',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									background: color,
									borderRadius: 5,
									padding: '0 5px',
									maxWidth: 165,
									minWidth: 165,
								}}
							>
								{title}
							</div>
						</div>
					</Tooltip>
				)
			},
		},
		{
			title: 'Дата обновления записи в системе по МСК',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: '10%',
			align: 'center' as const,
			render: (date: any, action: any) => {
				if (!date) return <div style={{ padding: '0 8px' }}>Нет данных</div>
				return (
					<div style={{ padding: '0 8px' }}>
						{dayjs(date).format('DD.MM.YYYY HH:mm')}
					</div>
				)
			},
		},
		{
			title: 'История',
			dataIndex: 'history',
			key: 'history',
			width: '12%',
			align: 'center' as const,
			render: (history: any, action: any) => {
				if (!history || history.length < 1) {
					return <div>Нет данных</div>
				}

				const [latest, previous] = history

				return (
					<div style={{ padding: '0 8px' }}>
						<HistoryHover
							title=' '
							width='100%'
							icon={<InfoCircleOutlined size={40} />}
							isShowHover={true}
							hoverData={history}
							type='promotions'
						/>
						<div
							style={{ display: 'flex', flexDirection: 'column', fontSize: 12 }}
						>
							<UpdateDate updatedAt={latest?.updatedAt} />

							{latest?.participationPercentage !==
								previous?.participationPercentage && (
								<span>
									<span style={{ fontWeight: 500 }}>Процент Участия:</span>{' '}
									{previous?.participationPercentage}% →{' '}
									{latest?.participationPercentage}%
								</span>
							)}

							{latest?.inPromoActionsLeftovers !==
								previous?.inPromoActionsLeftovers && (
								<span>
									<span style={{ fontWeight: 500 }}>Остатки по акциям:</span>{' '}
									{previous?.inPromoActionsLeftovers} →{' '}
									{latest?.inPromoActionsLeftovers}
								</span>
							)}

							{latest?.inPromoActionsTotal !==
								previous?.inPromoActionsTotal && (
								<span>
									<span style={{ fontWeight: 500 }}>
										Всего товаров в акциях:
									</span>{' '}
									{previous?.inPromoActionsTotal} →{' '}
									{latest?.inPromoActionsTotal}
								</span>
							)}

							{latest?.notInPromoActionsTotal !==
								previous?.notInPromoActionsTotal && (
								<span>
									<span style={{ fontWeight: 500 }}>
										Всего товаров не в акциях:
									</span>{' '}
									{previous?.notInPromoActionsTotal} →{' '}
									{latest?.notInPromoActionsTotal}
								</span>
							)}
						</div>
					</div>
				)
			},
		},
		{
			title: 'Просмотрено',
			dataIndex: 'isNew',
			key: 'isNew',
			width: '5%',
			align: 'center' as const,
			render: (isNew: boolean, action: any) => (
				<Checkbox
					checked={!isNew}
					disabled={loadingCheckboxId === action.id}
					onChange={() => {
						console.log(action.id)
						setLoadingCheckboxId(action.id)
						setPromotionChangeSeen({
							id: action.id,
							seen: isNew,
						})
					}}
				>
					{loadingCheckboxId === action.id && <Spin size='small' />}
				</Checkbox>
			),
		},
	]

	return (
		<>
			<div style={{ backgroundColor: '#f8f8f8' }}>
				<Row gutter={6} align={'middle'} justify={'end'} style={{ gap: 5 }}>
					<Col
						style={{
							width: '275px',
							paddingTop: 0,
							paddingBottom: 0,
						}}
					>
						<Button
							type='primary'
							onClick={handleSetAllSeen}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Установить все просмотренными
						</Button>
					</Col>
					<Col style={{ width: '300px', paddingTop: 0, paddingBottom: 0 }}>
						<Button
							type='primary'
							onClick={handleSetAllNotSeen}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Установить все не просмотренными
						</Button>
					</Col>
					<Col style={{ width: '120px' }}>
						<PickSeller
							getSellersFilterItems={getSellersFilterItems}
							selectedSeller={selectedSeller}
						/>
					</Col>
					<Col style={{ width: '160px' }}>
						<PickIsNew
							selectedSeenType={selectedSeenType}
							setSelectedSeenType={setSelectedSeenType}
						/>
					</Col>
					<Col style={{ width: '180px' }}>
						<PickChangeType
							selectedType={selectedChangeType}
							setSelectedType={setSelectedChangeType}
							data={promotionChangeTypesData}
							title={'Тип изменений'}
						/>
					</Col>
					<Col style={{ width: '200px' }}>
						<PickChangeType
							selectedType={promotions}
							setSelectedType={setPromotions}
							data={actionsData}
							title={'Выберите акцию'}
						/>
					</Col>
					<Col style={{ width: '130px' }}>
						<PickActionType
							selectedActionType={selectedActionType}
							setSelectedActionType={setSelectedActionType}
						/>
					</Col>
					<Col>
						<RangePicker
							onChange={onChangeDate}
							placeholder={['Начало', 'Конец']}
							separator={<Icon component={rangePickerArrow} />}
							format={'DD.MM.YYYY'}
							value={[dateSortStart, dateSortEnd]}
						/>
					</Col>
				</Row>

				<Row
					className='container'
					style={{ marginTop: '10px', height: '100%' }}
				>
					{nomenclaturesLoading ? (
						<div
							style={{
								margin: '0 auto',
								padding: '150px',
								textAlign: 'center',
							}}
						>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
						</div>
					) : nomenclaturesError ? (
						<div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
							Ошибка загрузки данных. Пожалуйста, попробуйте еще раз.
						</div>
					) : (
						<Col span={24}>
							<Table
								columns={columns}
								locale={{
									emptyText: 'Нет данных',
									filterTitle: 'Фильтр',
									filterReset: 'Очистить',
									filterEmptyText: 'Нет фильтров',
								}}
								dataSource={data ? data.data : []}
								pagination={false}
								rowKey={(record: any) => record.id}
								tableLayout='fixed'
								scroll={{
									x: 'max-content',
									y: '65vh',
									// y: '76dvh',
								}}
								size='small'
								style={{ width: '100%' }}
							/>

							<Col span={24}>
								<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
									<Pagination
										onChange={page => setCurrentPage(page)}
										current={currentPage}
										defaultCurrent={1}
										pageSize={parseInt(currentPageSize)}
										total={data?.total}
										style={{
											textAlign: 'center',
											padding: '16px 0',
										}}
										showSizeChanger={false}
									/>
									<Col style={{ width: '190px' }}>
										<PickPageSize
											currentPageSize={currentPageSize}
											setCurrentPage={setCurrentPage}
											setCurrentPageSize={setCurrentPageSize}
										/>
									</Col>
								</Row>
							</Col>
						</Col>
					)}
				</Row>
			</div>
			<ConfirmationModal
				isOpen={confirmSetAllSeenVisible}
				setIsOpen={setConfirmSetAllSeenVisible}
				confirm={confirmSetAllSeen}
				title={'Вы уверены, что хотите просмотреть все акции?'}
			/>
			<ConfirmationModal
				isOpen={confirmUnsetAllSeenVisible}
				setIsOpen={setConfirmUnsetAllSeenVisible}
				confirm={confirmUnsetAllSeen}
				title={'Вы уверены, что хотите отменить просмотр всех акций?'}
			/>
		</>
	)
}
