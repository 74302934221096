import React from 'react'
import { Table } from 'antd'

interface DataType {
	key: string
	name: string
	avg: number
	[date: string]: number | string
}

interface DynamicTableProps {
	data: DataType[]
}

const DynamicTable: React.FC<DynamicTableProps> = ({ data }) => {
	const dates = Array.from(
		new Set(
			data.flatMap(item =>
				Object.keys(item).filter(key => /^\d{4}-\d{2}-\d{2}$/.test(key))
			)
		)
	)

	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			width: '5%',
		},
		{
			title: 'Ср. знач.',
			dataIndex: 'avg',
			key: 'avg',
			width: '3%',
			align: 'center' as const,
			render: (value: number) => value.toFixed(2),
		},
		...dates.map(date => ({
			title: date,
			dataIndex: date,
			key: date,
			width: '4%',
			align: 'center' as const,
			render: (value: number) => (value ? value.toFixed(2) : '-'),
		})),
	]

	return (
		<Table
			locale={{
				emptyText: 'Нет данных',
				filterTitle: 'Фильтр',
				filterReset: 'Очистить',
				filterEmptyText: 'Нет фильтров',
			}}
			dataSource={data || []}
			columns={columns}
			// rowKey={(record: any) => record.id}
			// scroll={{
			// 	x: 'max-content',
			// 	y: '65vh',
			// }}
			size='small'
			pagination={false}
			style={{ width: '100%' }}
			bordered
		/>
	)
}

export default DynamicTable
