import React from 'react'
import { Button, Checkbox } from 'antd'
import dayjs from 'dayjs'
import { downloadFinRecordFile } from '../../../api/documentMirror'
import { formatDate } from 'utils/formatDate'
import { useNavigate } from 'react-router-dom'
const today = new Date()

const actionTypes: any = {
	regular: { title: 'Акция', color: 'rgba(70, 130, 180, 0.6)' },
	auto: { title: 'Автоакция', color: 'rgba(255, 203, 10, 0.6)' },
	special: {
		title: 'Специальное предложение',
		color: 'rgba(34, 139, 34, 0.6)',
	},
	sale: { title: 'Дни распродаж', color: 'rgba(255, 10, 255, 0.6)' },
}

const ActionItem = ({
	item,
	onCheck,
	setLoadingCheckboxId,
	loadingCheckboxId,
}: any) => {
	const navigate = useNavigate()
	const actionDates = `${formatDate(new Date(item.startDate))} - ${formatDate(
		new Date(item.endDate)
	)}`

	const handleClick = () => {
		navigate(`/actions/actions-calendar/${item.id || 0}`)
	}

	const getActionStatus = (
		startDate: Date | string,
		endDate: Date | string
	) => {
		const start = new Date(startDate)
		const end = new Date(endDate)
		if (today >= start && today <= end)
			return { status: 'В процессе', color: 'rgba(34, 239, 34, 0.6)' }
		else if (today > end)
			return { status: 'Окончена', color: 'rgba(234, 39, 34, 0.6)' }
		else if (today < start)
			return { status: 'Предстоящая', color: 'rgba(255, 163, 10, 0.6)' }
		else return { status: 'Не определен', color: 'rgba(25, 20, 120, 0.6)' }
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				borderRadius: '10px',
				padding: '15px',
				marginBottom: '10px',
				boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '10px',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: 10,
					}}
				>
					<div
						style={{
							backgroundColor: actionTypes[item.type].color,
							borderRadius: '5px',
							padding: '8px 12px',
						}}
					>
						<p style={{ color: '#303030', fontWeight: 'bold', margin: 0 }}>
							{actionTypes[item.type].title}
						</p>
					</div>
					<div
						style={{
							backgroundColor: getActionStatus(item.startDate, item.endDate)
								.color, //actionTypes[item.type].color,
							borderRadius: '5px',
							padding: '8px 12px',
						}}
					>
						<p style={{ color: '#303030', fontWeight: 'bold', margin: 0 }}>
							{getActionStatus(item.startDate, item.endDate).status}
						</p>
					</div>
					{onCheck && (
						<span>
							Скрыть
							<Checkbox
								checked={!!item.hidden}
								disabled={loadingCheckboxId === item.id}
								style={{ marginLeft: 5 }}
								onChange={() => {
									console.log(item.id)
									setLoadingCheckboxId(item.id)
									onCheck({
										id: item.id,
										hide: !item.hidden,
									})
								}}
							/>
						</span>
					)}
				</div>
				<p style={{ margin: 0, fontSize: '0.9em', color: '#555' }}>
					Даты акции: {actionDates}
				</p>
			</div>
			<div
				className='document-page-items-users'
				style={{ paddingLeft: '10px', cursor: 'pointer' }}
				onClick={handleClick}
			>
				<p className='item-title'>{`${item.name}`}</p>
			</div>
		</div>
	)
}

export default ActionItem
