import React from 'react'
import { UpdateDate } from './UpdateDate'

export const NomenclatureHistory = ({ hoverData }: any) => {
	return hoverData.map((data: any, index: number) => (
		<div key={index}>
			<div style={{ fontWeight: 'bold' }}>Изменение {index + 1}:</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 7,
					width: 150,
				}}
			>
				<UpdateDate updatedAt={data.updatedAt} />
				<>
					<span>{data.inAction ? 'У' : 'Не у'}частвует в акции,</span>
					<span>{data.isNew ? 'П' : 'Не п'}росмотрен,</span>
					<span>
						<span style={{ fontWeight: 600 }}>Скидка</span>: {data.planDiscount}
						%
					</span>
				</>
			</div>
		</div>
	))
}
