import React from 'react'
import { UpdateDate } from './UpdateDate'

export const PromotionsChange = ({ hoverData }: any) => {
	return hoverData.length > 1 ? (
		hoverData.map((data: any, index: number) => (
			<div key={index}>
				<div style={{ fontWeight: 'bold' }}>Изменение {index + 1}:</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 7,
					}}
				>
					<UpdateDate updatedAt={data.updatedAt} />
					<>
						<span>
							<span style={{ fontWeight: 500 }}>Процент Участия</span>:{' '}
							{data.participationPercentage}%
						</span>
						<span>
							<span style={{ fontWeight: 500 }}>Остатки по акциям</span>:{' '}
							{data.inPromoActionsLeftovers}
						</span>
						<span>
							<span style={{ fontWeight: 500 }}>Всего товаров в акциях</span>:{' '}
							{data.participationPercentage}
						</span>
						<span>
							<span style={{ fontWeight: 500 }}>Всего товаров не в акциях</span>
							: {data.notInPromoActionsTotal}
						</span>
						<span>
							<span style={{ fontWeight: 500 }}>Процент Участия</span>:{' '}
							{data.participationPercentage}%
						</span>
					</>
				</div>
			</div>
		))
	) : (
		<p>Нет изменений</p>
	)
}
